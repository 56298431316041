import React, { useState, useRef, useEffect } from 'react';
import styles from '../../../assets/css/styling.module.css';
import styles1 from '../../../assets/css/rstyling.module.css';
import Create_Question_With_Checkbox from '../../../assets/components/create-survey-questions/Create_Question_With_Checkbox';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Create_Question_With_LongText from '../../../assets/components/create-survey-questions/Create_Question_With_Longtext';
import Create_Question_With_Image from '../../../assets/components/create-survey-questions/Create_Question_With_Image';
import Create_Question_With_Imagetext from '../../../assets/components/create-survey-questions/Create_Question_With_Imagetext';
import Create_Question_With_Video from '../../../assets/components/create-survey-questions/Create_Question_With_Video';
import Create_Question_With_Videotext from '../../../assets/components/create-survey-questions/Create_Question_With_Videotext';
import Create_Question_With_Grid from '../../../assets/components/create-survey-questions/Create_Question_With_Grid';
import Create_Question_With_Spectrum from '../../../assets/components/create-survey-questions/Create_Question_With_Spectrum';
import { Input, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import PageHeader from '../../../assets/components/pageheader/PageHeader';
import CreateIcon from '../../../assets/images/create_icon.png'
import { useNavigate, useParams, useLocation } from 'react-router';
import ResizeDetector from 'react-resize-detector';
import SurveyIcon from '../../../assets/images/survey_icon.png'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Create_Question_With_Radiobtn from '../../../assets/components/create-survey-questions/Create_Question_With_Radiobtn';
import { createActivity, getActivity, getActivityImg, updateActivity } from '../../../actions/activities_actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert'
import ArrowLeft from '../../../assets/images/arrow_left.png'
import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import CryptoJS from 'crypto-js';
import Create_Question_With_Slider from '../../../assets/components/create-survey-questions/Create_Question_With_Slider';





const CreateMiniSurvey = (props) => {

    const history = useNavigate()
    const location = useLocation();
    const currentPath = location.pathname;
    const params = useParams()
    const formRef = useRef()
    const dispatch = useDispatch()
    const [isSave, setSave] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [isEdit, setEditMode] = useState(false)
    const [isPreview, setPreviewMode] = useState(false)
    const [isPublish, setPublishMode] = useState(false)
    const [qsImg, setqsImg] = useState({})

    const [surveyData, setSurveyData] = useState({
        name: '',
        description: '',
        quota: []
    })

    const [surveyContent, set_surveyContent] = useState({
        pages: [{
            pageName: "Page-1",
            questions: [],
        }],
    })

    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedQuestionType, setSelectedQuestionType] = useState('radio');
    const [quota, setQuota] = useState([{ type: '', name: '', limit: '' }]);
    const [criteria, setCriteria] = useState([{ type: '', name: ''}]);
    // Check if questions are complete
    const validateQuestions = (question) => {

        // check for question text and option text values
        if (question.questionType === "radio" && question.questionText !== '' && question.options.length > 0) {

            for (let i = 0; i < question.options.length; i++) {

                if (question.options[i] === '')
                    return false
            }

            return true
        }
        else if (question.questionType === "radio script" && question.questionText !== '' && question.options.length > 0) {

            for (let i = 0; i < question.options.length; i++) {

                if (question.options[i] === '')
                    return false
            }

            return true
        }

        else if (question.questionType === "checkbox" && question.questionText !== '' && question.options.length > 0) {

            for (let i = 0; i < question.options.length; i++) {

                if (question.options[i] === '')

                    return false
            }

            return true
        }

        else if (question.questionType === "checkbox script" && question.questionText !== '' && question.options.length > 0) {

            for (let i = 0; i < question.options.length; i++) {

                if (question.options[i] === '')

                    return false
            }

            return true
        }

        else if (question.questionType === "spectrum" && question.questionText !== '' && question.options.length > 0) {

            for (let i = 0; i < question.options.length; i++) {

                if (question.options[i].text === '' && question.options[i].image === '')

                    return false
            }

            return true
        }
        else if (question.questionType === "slider" && question.questionText !== '' && question.options.length > 0) {

            for (let i = 0; i < question.options.length; i++) {

                if (question.options[i].text === '' && question.options[i].image === '')

                    return false
            }

            return true
        }
        else if (question.questionType === "grid" && question.questionText !== '' && question.columns.length > 0 && question.rows.length > 0) {

            for (let i = 0; i < question.columns.length; i++) {

                if (question.columns[i].text === '' && question.columns[i].image === '')

                    return false
            }

            for (let i = 0; i < question.rows.length; i++) {

                if (question.rows[i].text === '' && question.rows[i].image === '')

                    return false
            }

            return true
        }


        // check for question text
        else if (question.questionType === "long text" && question.questionText !== '')
            return true

        else if (question.questionType === "image" && question.questionText !== '')
            return true

        else if (question.questionType === "video" && question.questionText !== '')
            return true

        else if (question.questionType === "image text" && question.questionText !== '')
            return true

        else if (question.questionType === "video text" && question.questionText !== '')
            return true
        else
            return false

    }

    // For adding a new question
    const addQuestion = () => {
        setSave(false)
        let pages = [...surveyContent.pages];

        switch (selectedQuestionType) {
            case 'radio':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionFile: "",
                    questionVideo: '',
                    mediaType: "",
                    options: [],
                    selected: [],
                    required: false,
                    isOtherAllowed: false,
                    isRandomOrder: false
                })
                break;

            case 'radio script':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionFile: "",
                    questionVideo: '',
                    mediaType: "",
                    options: [],
                    selected: [],
                    required: false,
                    isOtherAllowed: false,
                    isRandomOrder: false
                })
                break;

            case 'checkbox':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    mediaType: "",
                    questionFile: "",
                    questionVideo: '',
                    options: [],
                    selected: [],
                    required: false,
                    isOtherAllowed: false,
                    isRandomOrder: false
                })
                break;
            case 'checkbox script':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    mediaType: "",
                    questionFile: "",
                    questionVideo: '',
                    options: [],
                    selected: [],
                    required: false,
                    isOtherAllowed: false,
                    isRandomOrder: false
                })
                break;

            case 'long text':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo: '',
                    questionFile: "",
                    mediaType: "",
                    answer: "",
                    required: false,
                    textLimit: { max: '', min: '' }
                })
                break;

            case 'image':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo: '',
                    mediaType: "",
                    questionFile: "",
                    image: [],
                    required: false,
                    filesSize: '',
                    filesLimit: '',
                    isComment: false,
                })
                break;

            case 'image text':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo: '',
                    mediaType: "",
                    questionFile: "",
                    image: [],
                    answer: "",
                    required: false,
                    filesSize: '',
                    filesLimit: '',
                    textLimit: { max: '', min: '' }
                })
                break;

            case 'video':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo: '',
                    mediaType: "",
                    questionFile: "",
                    video: [],
                    required: false,
                    filesSize: '',
                    filesLimit: '',
                    isComment: false
                })
                break;

            case 'video text':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionVideo: '',
                    mediaType: "",
                    questionFile: "",
                    video: [],
                    answer: "",
                    required: false,
                    isRandomOrder: false,
                    filesSize: '',
                    filesLimit: '',
                    textLimit: { max: '', min: '' }
                })
                break;

            case 'grid':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionFile: "",
                    questionVideo: '',
                    mediaType: "",
                    rows: [{ text: '', image: '' }],
                    columns: [{ text: '', image: '' }],
                    uniqueSelected: false,
                    selected: [],
                    required: false,
                    isRandomOrder: false,
                    isComment: false
                })
                break;

            case 'spectrum':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionFile: "",
                    questionVideo: '',
                    options: [],
                    selected: "",
                    required: false,
                    // isComment: false,
                    // isRandomOrder: false,
                    mediaType: ""
                })
                break;

            case 'slider':

                pages[selectedTab].questions.push({
                    questionType: selectedQuestionType,
                    questionText: "",
                    questionFile: "",
                    questionVideo: '',
                    options: [],
                    selected: "",
                    required: false,
                    // isComment: false,
                    // isRandomOrder: false,
                    mediaType: ""
                })
                break;

            default:
                break;
        }

        set_surveyContent({ ...surveyContent, pages });
    }

    // Reordering after drag n drop
    const Reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };
    const onDragEnd = (result) => {

        // dropped outside the list
        if (!result.destination) {
            // no-change
            return;
        }

        if (result.type === "QUESTIONS") {
            // Reorder question
            const orderedQuestions = Reorder(
                surveyContent.pages[selectedTab].questions,
                result.source.index,
                result.destination.index
            );

            let pages = [...surveyContent.pages]

            pages[selectedTab].questions = orderedQuestions;

            set_surveyContent({
                ...props.surveyContent,
                pages: [...pages]
            })

        } else {

            if (result.type.indexOf(' ') > 0) {

                let splitedValue = result.type.split(' ')

                // Rows reordering in grid question

                if (splitedValue[0] === 'row') {
                    const orderedRows = Reorder(
                        surveyContent.pages[selectedTab].questions[parseInt(splitedValue[1])].rows,
                        result.source.index,
                        result.destination.index
                    );
                    let pages = [...surveyContent.pages]

                    pages[selectedTab].questions[parseInt(splitedValue[1])].rows = orderedRows;

                    set_surveyContent({
                        ...props.surveyContent,
                        pages: [...pages]
                    })

                } else {

                    // Col Reordering in grid question

                    const orderedCol = Reorder(
                        surveyContent.pages[selectedTab].questions[parseInt(splitedValue[1])].columns,
                        result.source.index,
                        result.destination.index
                    );
                    let pages = [...surveyContent.pages]

                    pages[selectedTab].questions[parseInt(splitedValue[1])].columns = orderedCol;

                    set_surveyContent({
                        ...props.surveyContent,
                        pages: [...pages]
                    })

                }

            } else {

                // Options Reordering

                const orderedOptions = Reorder(
                    surveyContent.pages[selectedTab].questions[parseInt(result.type)].options,
                    result.source.index,
                    result.destination.index
                );

                let pages = [...surveyContent.pages]

                pages[selectedTab].questions[parseInt(result.type, 10)].options = orderedOptions;

                set_surveyContent({
                    ...props.surveyContent,
                    pages: [...pages]
                })

            }

        }
    }

    const getQuestionNo = (tabNo) => {
        if (tabNo > 0) {
            let quesNo = 0;

            for (let i = 0; i < tabNo; i++)
                quesNo += surveyContent.pages[i].questions.length;

            return quesNo;

        } else
            return 0;

    }

    let questionNo = getQuestionNo(selectedTab);
    const questionList = (question, questionIndex, dragHandleProps, isValidQuestion) => {
        questionNo++

        switch (question.questionType) {
            case 'radio':
                return <Create_Question_With_Radiobtn
                    id={'radio' + CryptoJS.lib.WordArray.random(5)}
                    //id={'radio' + CryptoJS.lib.WordArray.random(5)}
                    name={selectedTab + Math.floor(Math.random() * 100) + (questionIndex + 1)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    questionType={question.questionType}
                    required={question.required}
                    options={question.options}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    isValidQuestion={isValidQuestion}
                    isRandomOrder={question.isRandomOrder}
                />
            case 'radio script':
                return <Create_Question_With_Radiobtn
                    id={'radio script' + CryptoJS.lib.WordArray.random(5)}
                    //id={'radio' + CryptoJS.lib.WordArray.random(5)}
                    name={selectedTab + Math.floor(Math.random() * 100) + (questionIndex + 1)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    questionType={question.questionType}
                    required={question.required}
                    options={question.options}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    isValidQuestion={isValidQuestion}
                    isRandomOrder={question.isRandomOrder}
                />

            case 'checkbox':

                return <Create_Question_With_Checkbox
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionType={question.questionType}
                    questionFile={question.questionFile}
                    options={question.options}
                    questionIndex={questionIndex}
                    required={question.required}
                    pageIndex={selectedTab}
                    setContent={set_surveyContent}
                    allContent={surveyContent}
                    dragHandleProps={dragHandleProps}
                    id={'check' + CryptoJS.lib.WordArray.random(5)}
                    isValidQuestion={isValidQuestion}
                    isRandomOrder={question.isRandomOrder}
                />

            case 'checkbox script':

                return <Create_Question_With_Checkbox
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionType={question.questionType}
                    questionFile={question.questionFile}
                    options={question.options}
                    questionIndex={questionIndex}
                    required={question.required}
                    pageIndex={selectedTab}
                    setContent={set_surveyContent}
                    allContent={surveyContent}
                    dragHandleProps={dragHandleProps}
                    id={'check script' + CryptoJS.lib.WordArray.random(5)}
                    isValidQuestion={isValidQuestion}
                    isRandomOrder={question.isRandomOrder}
                />

            case 'long text':

                return <Create_Question_With_LongText
                    id={'longText' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    required={question.required}
                    textLimit={question.textLimit}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                />

            case 'image':

                return <Create_Question_With_Image
                    id={'image' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    required={question.required}
                    filesSize={question.filesSize}
                    filesLimit={question.filesLimit}
                    isComment={question.isComment}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}

                />

            case 'image text':

                return <Create_Question_With_Imagetext
                    id={'image-text' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    required={question.required}
                    textLimit={question.textLimit}
                    filesSize={question.filesSize}
                    filesLimit={question.filesLimit}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                />

            case 'video':

                return <Create_Question_With_Video
                    id={'video' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    required={question.required}
                    filesSize={question.filesSize}
                    filesLimit={question.filesLimit}
                    isComment={question.isComment}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                />

            case 'video text':

                return <Create_Question_With_Videotext
                    id={'video-text' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    required={question.required}
                    textLimit={question.textLimit}
                    filesSize={question.filesSize}
                    filesLimit={question.filesLimit}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                />

            case 'grid':

                return <Create_Question_With_Grid
                    id={'grid' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    questionFile={question.questionFile}
                    rows={question.rows}
                    columns={question.columns}
                    uniqueSelected={question.uniqueSelected}
                    required={question.required}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                    isComment={question.isComment}
                    isRandomOrder={question.isRandomOrder}
                />

            case 'spectrum':

                return <Create_Question_With_Spectrum
                    id={'spectrum' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    options={question.options}
                    required={question.required}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                    isComment={question.isComment}
                    isRandomOrder={question.isRandomOrder}
                    questionFile={question.questionFile}
                    mediaType={question.mediaType}
                />

            case 'slider':

                return <Create_Question_With_Slider
                    id={'slider' + CryptoJS.lib.WordArray.random(5)}
                    questionNo={questionNo}
                    questionText={question.questionText}
                    questionVideo={question.questionVideo}
                    options={question.options}
                    required={question.required}
                    pageIndex={selectedTab}
                    questionIndex={questionIndex}
                    allContent={surveyContent}
                    setContent={set_surveyContent}
                    dragHandleProps={dragHandleProps}
                    questionType={question.questionType}
                    isValidQuestion={isValidQuestion}
                    isComment={question.isComment}
                    isRandomOrder={question.isRandomOrder}
                    questionFile={question.questionFile}
                    mediaType={question.mediaType}
                />


            default:
                break;
        }
    }

    const onPreview = () => {
        if (formRef.current) {
            setPreviewMode(true)
            formRef.current.handleSubmit()
        }
    }

    const getPageHeader = () => {
        switch (currentPath) {
            case '/admin/create-minisurvey':
                return <PageHeader
                    heading={"Create Quick Survey"} img={CreateIcon}
                    btnText="Preview"
                    isDisable={surveyContent.pages[0].questions.length > 0 ? false : true}
                    btnClick={() => onPreview()}
                />
                break;

            case '/admin/edit-minisurvey/' + params.activityid:
                return <div className="activity_header"><PageHeader
                    heading={"Edit Quick Survey"} img={SurveyIcon}
                    btnText="Preview"
                    isDisable={surveyContent.pages[0].questions.length > 0 ? false : true}
                    btnClick={() => onPreview()}
                /></div>
                break;

            default:
                return <PageHeader
                    heading={"Create Quick Survey"} img={CreateIcon}
                    btnText="Preview"
                    isDisable={surveyContent.pages[0].questions.length > 0 ? false : true}
                    btnClick={() => onPreview()}
                />
                break;
        }
    }

    const onSaveSurvey = (values) => {

        setSave(true)
        let errCount = 1

        // Survey validation
        for (let i = 0; i < surveyContent.pages.length; i++) {
            for (let k = 0; k < surveyContent.pages[i].questions.length; k++) {
                if (validateQuestions(surveyContent.pages[i].questions[k]) === false) {
                    errCount++
                    break;
                }

            }
            if (i === surveyContent.pages.length - 1 && errCount === 1) {
                errCount = 0
            }

        }

        if (errCount === 0) {

            // calculating endDate of activity
            const startDate = new Date(values.startDate).getTime();
            const day_milli = 1000 * 60 * 60 * 24;
            const endDate = new Date(startDate + day_milli * (values.days - 1));

            const formData = new FormData()

            formData.append('name', values.name)
            formData.append('description', values.description)
            formData.append('activityType', 'Quick Survey')
            formData.append('startDate', values.startDate)
            formData.append('days', values.days)
            formData.append('endDate', endDate)
            console.log('quota ->', quota)

            if (Array.isArray(quota) && quota.every(item => typeof item === 'object' && item !== null && item.type != '' && item.name != '' && item.limit != '')) {
                quota.forEach((q, index) => {
                    formData.append(`quota[${index}][type]`, q.type);
                    formData.append(`quota[${index}][name]`, q.name);
                    formData.append(`quota[${index}][limit]`, q.limit);
                  });
              }
              


            
              console.log('criteria ->', criteria)
              if (Array.isArray(criteria) && criteria.every(item => typeof item === 'object' && item !== null && item.type != '' && item.name != '' && item.limit != '')) {
                criteria.forEach((criterion, index) => {
                    formData.append(`criteria[${index}][type]`, criterion.type);
                    formData.append(`criteria[${index}][name]`, criterion.name);
                   
                  });
              }

            
            //formData.append('type', 0)

            values.bannerImage && formData.append('bannerImage', values.bannerImage)

            isPreview ? formData.append('status', 'draft') : formData.append('status', 'scheduled')



            for (let i = 0; i < surveyContent.pages.length; i++) {
                formData.append(`pages[${i}][pageName]`, surveyContent.pages[i].pageName)

                for (let j = 0; j < surveyContent.pages[i].questions.length; j++) {
                    formData.append(`pages[${i}][questions][${j}][questionType]`, surveyContent.pages[i].questions[j].questionType)
                    formData.append(`pages[${i}][questions][${j}][questionText]`, surveyContent.pages[i].questions[j].questionText)
                    formData.append(`pages[${i}][questions][${j}][questionVideo]`, surveyContent.pages[i].questions[j].questionVideo)
                    formData.append(`pages[${i}][questions][${j}][required]`, surveyContent.pages[i].questions[j].required)
                    surveyContent.pages[i].questions[j].uniqueSelected && formData.append(`pages[${i}][questions][${j}][isUniqueSelected]`, surveyContent.pages[i].questions[j].uniqueSelected)
                    surveyContent.pages[i].questions[j].isRandomOrder && formData.append(`pages[${i}][questions][${j}][isRandomOrder]`, surveyContent.pages[i].questions[j].isRandomOrder)
                    surveyContent.pages[i].questions[j].filesSize && formData.append(`pages[${i}][questions][${j}][filesSize]`, surveyContent.pages[i].questions[j].filesSize)
                    surveyContent.pages[i].questions[j].filesLimit && formData.append(`pages[${i}][questions][${j}][filesLimit]`, surveyContent.pages[i].questions[j].filesLimit)
                    surveyContent.pages[i].questions[j].isComment && formData.append(`pages[${i}][questions][${j}][isAdditionalComment]`, surveyContent.pages[i].questions[j].isComment)
                    if (surveyContent.pages[i].questions[j].textLimit) {
                        surveyContent.pages[i].questions[j].textLimit.min && formData.append(`pages[${i}][questions][${j}][textLimit][min]`, surveyContent.pages[i].questions[j].textLimit.min)
                        surveyContent.pages[i].questions[j].textLimit.max && formData.append(`pages[${i}][questions][${j}][textLimit][max]`, surveyContent.pages[i].questions[j].textLimit.max)
                    }


                    if (surveyContent.pages[i].questions[j].mediaType === 'mp4') {
                        formData.append(`pages[${i}][questions][${j}][questionFile]`, surveyContent.pages[i].questions[j].questionFile)
                    } else {
                        surveyContent.pages[i].questions[j].questionFile && formData.append(`pages[${i}][questions][${j}][questionFile]`, surveyContent.pages[i].questions[j].questionFile)
                    }

                    if (surveyContent.pages[i].questions[j].options) {
                        for (let k = 0; k < surveyContent.pages[i].questions[j].options.length; k++) {
                            formData.append(`pages[${i}][questions][${j}][options][${k}]`, surveyContent.pages[i].questions[j].options[k])
                        }
                        if (surveyContent.pages[i].questions[j].isOtherAllowed) {
                            formData.append(`pages[${i}][questions][${j}][options][${surveyContent.pages[i].questions[j].options.length}]`, 'Other')
                        }
                    }

                    if (surveyContent.pages[i].questions[j].rows) {
                        for (let k = 0; k < surveyContent.pages[i].questions[j].rows.length; k++) {
                            surveyContent.pages[i].questions[j].rows[k].image && formData.append(`pages[${i}][questions][${j}][rows][${k}][image]`, surveyContent.pages[i].questions[j].rows[k].image)
                            surveyContent.pages[i].questions[j].rows[k].text && formData.append(`pages[${i}][questions][${j}][rows][${k}][text]`, surveyContent.pages[i].questions[j].rows[k].text)

                        }
                    }

                    if (surveyContent.pages[i].questions[j].columns) {
                        for (let k = 0; k < surveyContent.pages[i].questions[j].columns.length; k++) {
                            surveyContent.pages[i].questions[j].columns[k].image && formData.append(`pages[${i}][questions][${j}][columns][${k}][image]`, surveyContent.pages[i].questions[j].columns[k].image)
                            surveyContent.pages[i].questions[j].columns[k].text && formData.append(`pages[${i}][questions][${j}][columns][${k}][text]`, surveyContent.pages[i].questions[j].columns[k].text)

                        }
                    }


                }

            }

            Swal({
                title: "Please wait!",
                text: "Saving data...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });

            if (isEdit) {
                dispatch(updateActivity(params.activityid, formData, 0))
                    .then(response => {
                        Swal.stopLoading();
                        Swal.close();
                        isPublish ?
                            Swal({
                                title: response.payload.title ? response.payload.title : "Created",
                                text: response.payload.message ? response.payload.message : 'Quick Survey Created',
                                icon: 'success'
                            }).then(() => {
                                history('/admin/minisurvey')
                            })
                            :
                            history({
                                pathname: `/admin/preview-minisurvey/${response.payload.content._id}`,
                                state: { from: 'create' }
                            })
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again",
                            icon: 'error'
                        }).then(() => {
                            Swal.stopLoading();
                            Swal.close();
                        })
                    })
            } else {

                dispatch(createActivity(formData, 0))
                    .then(response => {
                        Swal.stopLoading();
                        Swal.close();
                        isPublish ?
                            Swal({
                                title: response.payload.title ? response.payload.title : "Created",
                                text: response.payload.message ? response.payload.message : 'Quick Survey Created',
                                icon: 'success'
                            }).then(() => {
                                history('/admin/minisurvey')
                            })
                            :
                            history({
                                pathname: `/admin/preview-minisurvey/${response.payload.content._id}`,
                                state: { from: 'create' }
                            })
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again",
                            icon: 'error'
                        }).then(() => {
                            Swal.stopLoading();
                            Swal.close();
                        })
                    })
            }

        }



    }

    // getting data for edit
    useEffect(() => {
        const editUrl = '/admin/edit-minisurvey/' + params.activityid
        if (currentPath === editUrl) {

            setEditMode(true)
            dispatch(getActivityImg(params.activityid))
                .then((response) => {
                    try {
                        console.log('banner img in edit mode->', response.payload.content.fileData)
                        setqsImg(response.payload.content.fileData)
                    } catch (err) {
                        console.log('img not available')
                    }

                })
                .catch(err => {
                    console.log(err)
                })
            dispatch(getActivity(params.activityid))
                .then((response) => {
                    set_surveyContent(response.payload.content.survey)
                    const startDate = new Date(response.payload.content.startDate)
                    // const picture = (response.payload.content.fileData)
                    // console.log('this is banner img->', picture)
                    const formattedDate = startDate.getFullYear() + "-" + ("0" + (startDate.getMonth() + 1)).slice(-2) + "-" + ("0" + (startDate.getDate())).slice(-2)
                    console.log('survey data to populate ->', response.payload.content)
                    const survey = {
                        name: response.payload.content.name,
                        description: response.payload.content.description,
                        days: response.payload.content.days,
                        startDate: formattedDate,
                        quota: response.payload.content.quota
                    }
                    setSurveyData(survey)
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false)
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Something went wrong",
                        icon: 'error'
                    })
                })
        } else {
            setLoading(false)
        }
    }, [])


    const handleQuotaChange = (index, field, value) => {
        const updatedQuota = [...quota];
        updatedQuota[index][field] = value;
        console.log('updated quota ->', updatedQuota)
        setQuota(updatedQuota);
      };

      const handleCriteriaChange = (index, field, value) => {
        const updatedCriteria = [...criteria];
        updatedCriteria[index][field] = value;
        console.log('updated quota ->', updatedCriteria)
        setCriteria(updatedCriteria);
      };
    
      const addQuotaRow = () => {
        setQuota(prev => [...prev, { type: '', name: '', limit: '' }]);
      };
      const addCriteriaRow = () => {
        setCriteria(prev => [...prev, { type: '', name: ''}]);
      };


    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>
                    <div className="create_activity">
                        {getPageHeader()}
                    </div>

                    {/* Back Icon */}
                    <span onClick={() => history("/admin/minisurvey")} style={{ cursor: 'pointer' }}>
                        <img src={ArrowLeft} alt="" width="8px" />
                        <label className="ml-2" style={{ cursor: 'pointer' }}>Back</label>
                    </span>

                    <div className="row">
                        <div className='col-12'>


                            <LoadingOverlay tag="div" active={isLoading}
                                styles={{
                                    overlay: (base) => ({
                                        ...base,
                                        background: "#fff",
                                        opacity: 0.5,
                                        // position: 'fixed',
                                        // top: '2%'
                                    }),
                                }}
                                spinner={
                                    <Loader color="#6c757d" active type="ball-pulse" />
                                }>

                                <Formik
                                    initialValues={surveyData}
                                    innerRef={formRef}
                                    enableReinitialize={true}
                                    onSubmit={(values) => {
                                        console.log("INNNNNNNNNNN", values)
                                        onSaveSurvey(values)

                                    }
                                    }


                                    const validationSchema={Yup.object().shape({
                                        name: Yup.string().required("* Activity Name is required"),
                                        description: Yup.string().required("* Description is required"),
                                        startDate: Yup.date().required("* Start Date is required"),
                                        days: Yup.number().required("* Duration is required"),

                                    })}


                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,

                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            setFieldValue
                                        } = props;
                                        return (
                                            <form autoComplete="off" onSubmit={handleSubmit}>

                                                {/* Start of form */}

                                                <div className='row _survey-steps_'>

                                                    <div className='col-12 mb-3'>
                                                        {/* Image Display & Dropzone */}
                                                        {values.bannerImage ? (

                                                            // Image Display
                                                            <div className="pt-3">
                                                                <div className="position-relative">
                                                                    <span className="questionImg_close" style={{ left: "97%", marginLeft: 0 }} onClick={() => setFieldValue('bannerImage', '')}>&times;</span>
                                                                    <img src={`data:${qsImg.type} ;base64, ${qsImg.data}`} className={`img-fluid border ${styles.banner_image}`} style={{ borderRadius: '10px', maxHeight: 400, width: 'max-content' }} alt="img" />
                                                                </div>
                                                            </div>

                                                        ) : (

                                                            // Image Drop Zone 
                                                            < div className="dropzone-wrapper dropzone-wrapper-sm">
                                                                <Dropzone multiple={false} accept={".jpg, .jpeg, .png"} onDrop={file => setFieldValue('bannerImage', file[0])} value={values.bannerImage}>
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <div className="dropzone-content" style={{ cursor: 'pointer' }}>
                                                                                <p>
                                                                                    <span className={styles.upload_media_icon} style={{ width: 100, height: 100, backgroundSize: 55, verticalAlign: "middle" }}></span>Try dropping image here, or click to select image to upload.
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            </div>

                                                        )}
                                                    </div>

                                                    <div className="col-12 form-group">
                                                        <label htmlFor="activityName">Activity Name</label>
                                                        <Input type="text"
                                                            className={errors.name && touched.name ? styles1.is_invalid : 'valid'}
                                                            name="name"
                                                            placeholder="Activity Name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                        />

                                                        {errors.name && touched.name && (
                                                            <div>
                                                                {errors.name}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-12 form-group">
                                                        <label htmlFor="description">Description</label>
                                                        <Input type="text"
                                                            className={errors.description && touched.description ? styles1.is_invalid : 'valid'}
                                                            name="description"
                                                            placeholder="Description"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.description}
                                                        />

                                                        {errors.description && touched.description && (
                                                            <div>
                                                                {errors.description}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="startDate">Start Date</label>
                                                        <Input type="date"
                                                            className={errors.startDate && touched.startDate ? styles1.is_invalid : 'valid'}
                                                            name="startDate"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.startDate}
                                                        />

                                                        {errors.startDate && touched.startDate && (
                                                            <div>
                                                                {errors.startDate}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-12 form-group">
                                                        <label htmlFor="duration">Duration</label>
                                                        <Input type="number"
                                                            className={errors.days && touched.days ? styles1.is_invalid : 'valid'}
                                                            name="days"
                                                            placeholder="Duration"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.days}
                                                        />

                                                        {errors.days && touched.days && (
                                                            <div>
                                                                {errors.days}
                                                            </div>
                                                        )}
                                                    </div>

                                                    {/* quota system */}
                                                    <div className='col-md-6 col-sm-12 col-12 form-group my-4'>
                                                    <label htmlFor="criteria">Set Quota</label>
                                                        {quota.map((criterion, index) => (
                                                            <div key={index}>
                                                                <select
                                                                    value={criterion.type}
                                                                    onChange={e => handleQuotaChange(index, 'type', e.target.value)}
                                                                    className='mt-4'
                                                                >
                                                                    <option value="">Select Type</option>
                                                                    <option value="city">City</option>
                                                                    <option value="gender">Gender</option>
                                                                    <option value="ageGroup">Age Group</option>
                                                                </select>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Name"
                                                                    value={criterion.name}
                                                                    onChange={e => handleQuotaChange(index, 'name', e.target.value)}
                                                                />
                                                                <Input
                                                                    type="number"
                                                                    placeholder="Limit"
                                                                    value={criterion.limit}
                                                                    onChange={e => handleQuotaChange(index, 'limit', e.target.value)}
                                                                />
                                                            </div>
                                                        ))}
                                                        <Button type="button" className='mt-4' onClick={addQuotaRow}>Add Quota</Button>
                                                    </div>

                                                    <div className='col-md-6 col-sm-12 col-12 form-group my-4'>
                                                    <label htmlFor="criteria">Set Criteria</label>
                                                        {criteria.map((criterion, index) => (
                                                            <div key={index}>
                                                                <select
                                                                    value={criterion.type}
                                                                    onChange={e => handleCriteriaChange(index, 'type', e.target.value)}
                                                                    className='mt-4'
                                                                >
                                                                    <option value="">Select Type</option>
                                                                    <option value="city">City</option>
                                                                    <option value="gender">Gender</option>
                                                                    <option value="ageGroup">Age Group</option>
                                                                </select>
                                                                <Input
                                                                    type="text"
                                                                    placeholder="Name"
                                                                    value={criterion.name}
                                                                    onChange={e => handleCriteriaChange(index, 'name', e.target.value)}
                                                                />
                                                                
                                                            </div>
                                                        ))}
                                                        <Button type="button" className='mt-4' onClick={addCriteriaRow}>Add Criteria</Button>
                                                    </div>

                                                </div>


                                                {/* Page Tabs */}
                                                <div className={`${styles.card_border} _survey-steps_`}>

                                                    <div className={styles.card_border_body + " pt-4 pb-1"}>

                                                        <label>Add Questions</label>
                                                        {selectedTab < surveyContent.pages.length ? (

                                                            //Show Page Content

                                                            <DragDropContext
                                                                onDragEnd={onDragEnd}
                                                            >

                                                                {
                                                                    <Droppable droppableId="droppable" type="QUESTIONS">
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                            >
                                                                                {surveyContent.pages[selectedTab].questions.map((question, questionIndex) => {
                                                                                    return <Draggable
                                                                                        key={questionIndex}
                                                                                        draggableId={questionIndex.toString()}
                                                                                        index={questionIndex}
                                                                                    >
                                                                                        {(provided, snapshot) => (
                                                                                            <div
                                                                                                ref={provided.innerRef}
                                                                                                {...provided.draggableProps}
                                                                                                className="mt-3"
                                                                                            >
                                                                                                {/* Questions */}

                                                                                                {isSave ? questionList(question, questionIndex, provided.dragHandleProps, validateQuestions(question)) :
                                                                                                    questionList(question, questionIndex, provided.dragHandleProps, true)
                                                                                                }


                                                                                            </div>
                                                                                        )}
                                                                                    </Draggable>
                                                                                })}
                                                                                {provided.placeholder}

                                                                            </div>
                                                                        )}
                                                                    </Droppable>
                                                                }
                                                            </DragDropContext>

                                                        ) : (
                                                            setSelectedTab(oldTab => oldTab - 1)
                                                        )}

                                                        {/* Add a Question */}
                                                        <div className="row my-3">

                                                            <div className="col-8 col-sm-9 col-lg-9 pr-0">

                                                                {/* Question Type */}
                                                                <div className="form-group mb-0">

                                                                    <select className="form-control" onChange={(e) => setSelectedQuestionType(e.target.value)}>
                                                                        <option value="radio">Radio</option>
                                                                        <option value="radio script">Radio Script</option>
                                                                        <option value="checkbox">Checkbox</option>
                                                                        <option value="checkbox script">Checkbox Script</option>
                                                                        <option value="long text">Long Text</option>
                                                                        <option value="image">Image</option>
                                                                        <option value="image text">Image with Text</option>
                                                                        <option value="video">Video</option>
                                                                        <option value="video text">Video with Text</option>
                                                                        <option value="grid">Grid</option>
                                                                        <option value="spectrum">Spectrum</option>
                                                                        <option value="slider">Slider</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 col-sm-3 col-lg-3 align-self-center">
                                                                <button className="btn btn-hover-shine text-light py-2 mr-2" style={{ backgroundColor: '#006FA7' }} type="button" onClick={() => addQuestion()}>{width <= 470 ? "Add" : "Add Question"}</button>
                                                                <button className="btn btn-hover-shine text-light py-2 " style={{ backgroundColor: '#006FA7' }} type="submit" onClick={() => setPublishMode(true)}>
                                                                    {values.status && values.status === 'draft' ? 'Publish' : 'Save'}
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }}

                                </Formik>

                            </LoadingOverlay>
                            {console.log('questions of survey ->', surveyContent)}
                        </div>
                    </div>



                </>
            )}
        />
    )


}

export default CreateMiniSurvey


