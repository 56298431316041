import React, { useEffect, useState } from "react";
import Criteria from "./Criteria";
import Finalization from "./Finalization";
import BasicInfo from './BasicInfo'
import { addProjectDocument, createProject, updateProject } from "../../../../actions/project_actions";
import Swal from 'sweetalert'
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const getNavStates = (indx, length) => {
    let styles = [];
    for (let i = 0; i < length; i++) {
        if (i < indx) {
            styles.push("done");
        } else if (i === indx) {
            styles.push("doing");
        } else {
            styles.push("todo");
        }
    }
    return { current: indx, styles: styles };
};

const checkNavState = (state, currentStep, stepsLength) => {
    if (currentStep > 0 && currentStep < stepsLength - 1) {
        return {
            ...state,
            showPreviousBtn: true,
            showNextBtn: true,
            compState: currentStep,
            navState: getNavStates(currentStep, stepsLength)
        };
    } else if (currentStep === 0) {
        return {
            ...state,
            showPreviousBtn: false,
            showNextBtn: true,
            compState: currentStep,
            navState: getNavStates(currentStep, stepsLength)

        };
    } else {
        return {
            ...state,
            showPreviousBtn: true,
            showNextBtn: false,
            compState: currentStep,
            navState: getNavStates(currentStep, stepsLength)
        };
    }
};

const FormSteps = (props) => {

    const dispatch = useDispatch()
    const history = useNavigate()
    const params = useParams()
    const [formInitialValues, setData] = useState({
        title: "",
        code: "",
        // brand: "",
        // company: "",
        // projectType: "",
        // researchType: "",
        // priority: "",
        // promotional: "",
        // isClosed: false,
        // country: "",
        // city: "",
        // compensation: "",
        // activeDays: "",
        // duration: "",
        // startDate: "",
        // endDate: "",
        // researchId:"",
        criteria: [],
    })

    const steps = [
        { name: "Basic Information", component: <BasicInfo next={(values) => next(values)} data={formInitialValues} isEdit={props.isEdit} isCopy={props.isCopy} /> },
        { name: "Criteria Setup", component: <Criteria next={(values) => next(values)} previous={() => previous} data={formInitialValues} isEdit={props.isEdit} isCopy={props.isCopy} /> },
        { name: "Date and Finalization", component: <Finalization next={(values) => next(values)} previous={() => previous} data={formInitialValues} isEdit={props.isEdit} isCopy={props.isCopy} /> },
    ]

    useEffect(() => {
        if ( state.compState === 0 && (props.isEdit || props.isCopy)) {
            const { _id, ...project } = props.project
            setData({ ...formInitialValues, ...project, brand: project.brand && project.brand._id, country: project.country && project.country._id , city: project.city && project.city._id })
        }
    }, [props.project]);
    
    const [state, setState] = useState({
        showPreviousBtn: false,
        showNextBtn: true,
        compState: 0,
        navState: getNavStates(0, steps.length),
    });

    const setNavState = (next) => {
        setState({
            ...state,
            navState: getNavStates(next, steps.length),
        });
        if (next < steps.length) {
            setState({  ...state,compState: next});
        }
        setState(checkNavState(state, next, steps.length));
    };

    const handleKeyDown = (evt) => {
        if (evt.which === 13) {
            next();
        }
    };

    const handleOnClick = (evt) => {
        if (
            evt.currentTarget.value === steps.length - 1 &&
            state.compState === steps.length - 1
        ) {
            setNavState(steps.length);
        } else {
            setNavState(evt.currentTarget.value);
        }
    };

    const next = (values) => {
        setData({ ...formInitialValues, ...values })

        if (state.compState === 2) {
            Swal({
                title: "Please wait!",
                text: "Saving data...",
                type: "info",
                buttons: false,
                closeModal: false,
                closeOnClickOutside: false
            });

            if (props.isEdit) {
                dispatch(updateProject(params.projectid, { ...formInitialValues, ...values }))
                    .then(response => {
                        Swal.stopLoading();
                        Swal.close();
                        Swal({
                            title: response.payload.title ? response.payload.title : "Edited",
                            text: response.payload.message ? response.payload.message : 'Project Edited',
                            icon: 'success'
                        }).then(() => {
                            history("/researcher/project")
                        })
                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again",
                            icon: 'error'
                        }).then(() => {
                            Swal.stopLoading();
                            Swal.close();
                        })
                    })
            } else {
                dispatch(createProject({ ...formInitialValues, ...values }))
                    .then(response => {

                        const doc = new FormData()
                        doc.append('document', formInitialValues.document)

                    
                        if (formInitialValues.document) {
                            dispatch(addProjectDocument(response.payload.content._id, doc))
                                .then(res => {
                                    Swal.stopLoading();
                                    Swal.close();
                                    Swal({
                                        title: response.payload.title ? response.payload.title : "Created",
                                        text: response.payload.message ? response.payload.message : 'Project Created',
                                        icon: 'success'
                                    }).then(() => {
                                        history("/researcher/project")
                                    })
                                }).catch(err => {
                                    Swal({
                                        title: err.response ? err.response.data.name : "Error",
                                        text: err.response ? err.response.data.content : "Please try again",
                                        icon: 'error'
                                    }).then(() => {
                                        Swal.stopLoading();
                                        Swal.close();
                                    })
                                })
                        } else {
                            Swal({
                                title: response.payload.title ? response.payload.title : "Created",
                                text: response.payload.message ? response.payload.message : 'Project Created',
                                icon: 'success'
                            }).then(() => {
                                history("/researcher/project")
                            })
                        }


                    })
                    .catch(err => {
                        Swal({
                            title: err.response ? err.response.data.name : "Error",
                            text: err.response ? err.response.data.content : "Please try again",
                            icon: 'error'
                        }).then(() => {
                            Swal.stopLoading();
                            Swal.close();
                        })
                    })
            }
        } else {
            setNavState(state.compState + 1);
        }
    };

    const previous = () => {
        if (state.compState > 0) {
            setNavState(state.compState - 1);
        }
    };
    const getClassName = (className, i) => {
        return className + "-" + state.navState.styles[i];
    };

    const renderSteps = () => {
        return steps.map((s, i) => (
            <li
                className={getClassName("form-wizard-step", i)}
                onClick={handleOnClick}
                key={i}
                value={i}
            >
                <em>{i + 1}</em>
                <span>{steps[i].name}</span>
            </li>
        ));
    };

    return <div className="multi_step">
        <div onKeyDown={handleKeyDown}>
            <ol className="forms-wizard">{renderSteps()}</ol>
            {steps[state.compState].component}

        </div>
    </div>;
};

export default FormSteps;

