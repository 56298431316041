import React, { useState, useEffect, useContext } from 'react';
import ResizeDetector from "react-resize-detector";
import { IoIosEye, IoIosEyeOff } from 'react-icons/io';
import { useDispatch } from 'react-redux'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { signup, SignupGoogle, signupFacebook, SignupTiktok } from '../../actions/auth_actions';
import Swal from 'sweetalert'
import styles from '../../assets/css/rstyling.module.css'
import styles3 from '../../assets/css/newStyling.module.css'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Input } from 'reactstrap';
import { useGoogleLogin } from '@react-oauth/google';
import { FcGoogle } from 'react-icons/fc';
import { IoLogoFacebook } from 'react-icons/io';
import { LoginSocialFacebook, LoginSocialTiktok } from 'reactjs-social-login';
import { userDetailsContext } from '../../UserDetailsProvider';
import SecureLS from 'secure-ls';
import axios from 'axios'
import { getLoggedInUser } from '../../actions/auth_actions';
import { FacebookProvider, LoginButton } from 'react-facebook';
import landing_bg_new from "../../assets/images/landing_page.png";
import landing_page_logo from '../../assets/images/landing_page_logo.png'
import login_bg_new from "../../assets/images/login_bg2.png";


export default function Choice2() {

    let { search } = useLocation()
    const dispatch = useDispatch()
    const history = useNavigate();
    const ls = new SecureLS()


    const { userDetails, setUserDetails } = useContext(userDetailsContext);
    // if users signup with google

    const query = new URLSearchParams(window.decodeURIComponent(search));
    const code = query.get('code')

    const [tiktokToken, set_tiktokToken] = useState('')

    const handleGoogleLoginSuccess = (tokenResponse) => {
        // clearCaches()
        const accessToken = tokenResponse.access_token;

        let referredBy = ''
        if (query.get('code')) {
            referredBy = query.get('code')
        }

        dispatch(SignupGoogle(accessToken, referredBy))



            .then(response => {

                setUserDetails(response.payload.content)
                ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.role });

                axios.defaults.headers.common['Authorization'] = `Bearer ${response.payload.content.accessToken}`;
                dispatch(getLoggedInUser())
                    .then(response1 => {

                        ls.set('accessUser', { user: response1.payload.content });
                        if (ls.get('accessToken').role) {
                            if (ls.get('accessToken').role === 3) {

                                //history('/login')
                                history('/phone')
                            }
                        }
                    })

                Swal({
                    title: "Signed Up",
                    text: "Signup with Google was successful",

                    icon: 'success'
                })
            })


            .catch(err => {

                if (err.response.data.name === "Duplication Error") {
                    Swal({
                        title: "Info",
                        text: "User already exists.",
                        icon: 'info'
                    });
                } else {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again!",
                        icon: 'error'
                    });
                }

            })


    }

    useEffect(() => {

        // Check if the user is accessing the app from an in-app browser
        const isInAppBrowser = /Instagram/.test(navigator.userAgent);

        if (isInAppBrowser) {
            alert('For the best experience, please open this link in Chrome browser.');
        }

        if (query.get('code')) {
            console.log('came here in code')
        }

    }, [])

    const Login = useGoogleLogin({ onSuccess: handleGoogleLoginSuccess });

    // if users signup with facebook
    const FacebookLogin = (response) => {
        console.log('fb login token ->', response)
        //clearCaches()
        let referredBy = ''
        if (query.get('code')) {
            referredBy = query.get('code')
        }


        const accessToken = response.data.accessToken
        dispatch(signupFacebook(accessToken, referredBy))

            .then(response => {
                setUserDetails(response.payload.content)
                console.log('just after fb sigup api ->', response.payload.content)
                ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.user.role });

                axios.defaults.headers.common['Authorization'] = `Bearer ${response.payload.content.accessToken}`;
                dispatch(getLoggedInUser())
                    .then(response1 => {
                        console.log('response1 fb signup after getLoggedInUser ->', response1)
                        ls.set('accessUser', { user: response1.payload.content });

                        if (ls.get('accessToken').role) {
                            if (ls.get('accessToken').role === 3) {
                                //history('/login')
                                history('/phone')
                            }
                        }
                    })
                Swal({
                    title: "Signed Up",
                    text: "Signup with Facebook was successful",

                    icon: 'success'
                })
            }
            )
            .catch(err => {

                if (err.response.data.name === "Duplication Error") {
                    Swal({
                        title: "Info",
                        text: "User already exists.",
                        icon: 'info'
                    });
                } else {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again!",
                        icon: 'error'
                    });
                }

            })

    }

    



 



    return (




        <>
            <div className="container-fluid p-2 px-4">
                <div className='row justify-content-center'>
                    <div className={`row p-2 ${styles3.login_container}`}>

                        <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                            <div className={`${styles3.login_img}`}
                                style={{
                                    backgroundImage: "url(" + login_bg_new + ")",
                                }} />


                        </div>

                        <div className="col-sm-7 col-12 bg-white align-self-center">
                            <div className='row justify-content-center'>
                                <div className={`${styles3.login_section}`}>
                                    {/* <ul className="nav justify-content-center pt-5">
        <li className="nav-item">
            <img src={landing_page_logo} alt="logo" width="250px" style={{ margin: '2rem 1rem 0 0' }} />
        </li >
       
    </ul> */}
                                    <div className=" container" >



                                        <div className="container text-center">
                                            <img className={`${window.innerWidth < 768 ? ' d-none' : styles3.login_logo}`} src={landing_page_logo} alt="logo" width="200px" />
                                            <p className={`${window.innerWidth < 768 ? ' mt-4' : ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1rem', paddingTop: '50px' }}>Join us to share your voice and shape product innovation.</p>

                                            <div>
                                                <button className={`btn-hover-shine btn btn-block py-3 mt-4`} style={{ backgroundColor: '', fontSize: '1rem', border: '1px solid', borderColor: '#495057', fontWeight: '350' }} onClick={() => Login()}><FcGoogle size={18} className='mx-3' />
                                                    Create account with Google
                                                </button>

                                                {/* <div className='row'>
            <div className='col'><hr></hr></div>
            <div className='col-2'><div className='py-2' style={{ color: 'rgb(173, 167, 167)', fontWeight: 'bold' }}>Or</div></div>
            <div className='col'><hr></hr></div>
          </div> */}


                                                <LoginSocialFacebook

                                                    appId='1666970400472102'


                                                    onResolve={(response) => {
                                                        FacebookLogin(response)
                                                    }}
                                                    onReject={(error) => {
                                                        console.log(error)
                                                    }}

                                                >
                                                    <button className={`btn-hover-shine btn btn-block py-3 mt-4`} style={{ backgroundColor: '', fontSize: '1rem', border: '1px solid', borderColor: '#495057', fontWeight: '350' }} ><IoLogoFacebook size={19} className='mx-3' style={{ color: '#4963ab' }} />
                                                        Create account with Facebook
                                                    </button>
                                                </LoginSocialFacebook>



                                               

                                                 {/*<button onClick={handleTikTokLogin}>Tiktok signup v2</button> */}

                                                <a href={`/signup`} className={`btn-hover-shine btn text-light btn-block py-3 mt-4`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }}>
                                                    Create account with Email
                                                </a>
                                            </div>
                                            <div className="mt-3">
                                                <label style={{ fontSize: '0.95rem', fontWeight: '400', color: '#888' }}>
                                                    Already have an account? &nbsp;
                                                    <a href="/login" style={{ color: '#006FA7' }}>
                                                        Login
                                                    </a>
                                                </label>

                                            </div>












                                        </div>
                                    </div>
                                </div>
                            </div>










                        </div>
                    </div>
                </div>
            </div>
        </>

























    )
}