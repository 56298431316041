import React, { useState, useEffect } from 'react'
import { TransitionGroup } from 'react-transition-group';
import { CardHeader, Card, CardBody } from "reactstrap";

import { VerticalTimeline } from "react-vertical-timeline-component";
import avatar from "../../../../../../assets/images/profile_placeholder.png";
import {  getAllSurveyReply } from '../../../../../../actions/activities_reply_actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loader from "react-loaders";
import LoadingOverlay from 'react-loading-overlay-ts';
import SurveyResponsesView from '../../../../../Admin/Project/Activity/view/SurveyResponsesView';
import CreateExcerpt from '../../view/Excerpts/CreateExcerpt';

const ScreenerAnalytics = () => {


    const surveyState = useSelector(state => state.SurveyReply)
    const dispatch = useDispatch()
    const params = useParams()
    const [surveyReply, setSurveyReplies] = useState([])
    const [isLoading, setLoading] = useState(true)

    const [isModalOpen, setModal] = useState(false)

    const [excerptData, setExcerptData] = useState({
        selectedString: '',
        note: '',
        activity: '',
        responseAuthor: ''
    })

    const resetData = () => {
        setExcerptData({
            selectedString: '',
            note: '',
            activity: '',
            responseAuthor: ''
        })
    }

    // Show Image inModal
    const showModal = (author, details) => {
        console.log("INN", window.getSelection(), details, author)
        resetData()
        setModal(!isModalOpen)

        setExcerptData(prevState => ({
            ...prevState,
            selectedString: window.getSelection().toString(),
            activityType: details.title,
            activityTitle: details.title,
            activity: params.activityid,
            responseAuthor: author
        }))

    }


    const getResponses = (page = 1, limit = 5) => {
        dispatch(getAllSurveyReply({ page: page, limit: limit, activityId: params.activityid }))
        .then((response) => {
            const replies = response.payload.content.replies;
        
            console.log('get surveys replies ->',replies)
           
            let result = [];
        
            for (let i = 0; i < replies.length; i++) {
                const reply = replies[i];
                const singleReply = {
                    replies: [],
                    author: reply.author,
                    created_at: reply.created_at,
                    replyId:reply._id,
                    replyLoi: reply.loi
                };
        
               
                if (reply.questions && reply.questions.length !=0 && reply.pages && reply.pages.length === 0 ) {
                   console.log('came in if block')
                    // Execute the previous logic block when we have only questions field in database
                    const repliesAnswers = reply.questions;
                    for (let j = 0; j < reply.activity.survey.pages.length; j++) {
                        for (let k = 0; k < reply.activity.survey.pages[j].questions.length; k++) {
                            repliesAnswers.map(ans => {
                                if (ans.questionId === reply.activity.survey.pages[j].questions[k]._id) {
                                    // saving question object, answer, and id
                                    singleReply.replies.push({
                                        ...reply.activity.survey.pages[j].questions[k],
                                        ...ans,
                                        _id: reply.activity.survey.pages[j].questions[k]._id
                                    });
                                }
                            });
                        }
                    }
                } else {
                  
                    // Execute the new logic block
                    // Iterate through all pages of the survey
                    // for (let j = 0; j < reply.activity.survey.pages.length; j++) {
                    //     console.log('came in else block for loop->')
                    //     const page = reply.activity.survey.pages[j];
        
                    //     // Iterate through all questions on the current page
                    //     for (let k = 0; k < page.questions.length; k++) {
                    //         const question = page.questions[k];
        
                    //         // Find the corresponding answer in repliesAnswers
                    //         const answer = reply.pages[j].questions[k];
        
                            
                    //         singleReply.replies.push({
                    //             ...question,
                    //             ...answer,
                    //             _id: question._id
                    //         });
                    //     }
                    // }
                    for (let j = 0; j < reply.activity.survey.pages.length; j++) {
                        const page = reply.activity.survey.pages[j];
                    
                        // Iterate through all questions on the current page in survey questionnaire
                        for (let k = 0; k < page.questions.length; k++) {
                            const question = page.questions[k];
                          
                    
                            // Find the corresponding answer from survey replies where IDs match
                            const answer = reply.pages[j].questions.find(ans => ans.questionId === question._id);
                           
                    
                            // Only push if matching answer is found
                            if (answer) {
                                singleReply.replies.push({
                                    ...question,
                                    ...answer,
                                    _id: question._id
                                });
                            }
                        }
                    }
                }
                if (singleReply !== undefined && singleReply !== null) {
                    result.push(singleReply);
                }
                //result.push(singleReply);
            
            result = result.filter(item => item !== undefined && item !== null);
            setSurveyReplies(result);
            }
            setLoading(false)
        })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        getResponses()
    }, [])

    const next = () => {
        getResponses(surveyState.currentPage + 1, 5)
    }

    return (
        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>

            <LoadingOverlay tag="div" active={isLoading}
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "#fff",
                        opacity: 0.5,
                        // position: 'fixed',
                        // top: '2%'
                    }),
                }}
                spinner={
                    <Loader color="#6c757d" active type="ball-pulse" />
                }>

                <CreateExcerpt
                    data={excerptData} setData={setExcerptData}
                    isModalOpen={isModalOpen} showModal={() => setModal(false)}
                />

                {
                    surveyReply.length > 0 ?
                        surveyReply.map((reply, index) => {
                            return <>
                                <Card className="main-card mb-3">
                                    {/* Author Details */}
                                    <CardHeader className="text-capitalize" style={{ fontWeight: '600', fontSize: '0.9rem' }}>
                                    <div className='px-2'>{`${index} )`}</div>
                                        <img src={reply.author && reply.author.profileImage ? reply.author.profileImage : avatar} alt="profile" width={32} height={32} className="rounded-circle mr-2" />
                                        {reply.author? reply.author.firstName: 'Respondent'}
                                        <div className='px-2'>{reply.replyLoi && reply.replyLoi}</div>
                                    </CardHeader>
                                    <CardBody>

                                        {/* Survey responses */}
                                        <VerticalTimeline layout="1-column"
                                            className="vertical-time-simple vertical-without-time">

                                            {
                                                reply.replies.map(question => {
                                                    return <div onClick={() => showModal(reply.author, question)}>
                                                        <SurveyResponsesView question={question} />
                                                    </div>
                                                })
                                            }

                                        </VerticalTimeline>
                                    </CardBody>
                                </Card>

                            </>
                        }) :
                        <div className={"pb-2 ml-5"}>
                            <div className="row px-3">
                                <div className="col -12 mt-3 align-self-center text-center">
                                    {/* Comment Counts */}
                                    <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>No Responses</span>
                                </div>
                            </div>
                        </div>
                }

                {
                    surveyState.totalPages > 0 && surveyState.totalPages !== surveyState.currentPage &&
                    <div className={"pb-2 ml-5"}>
                        <div className="row px-3">
                            <div className="col -12 mt-3 align-self-center text-center" onClick={() => next()} >
                                {/* Comment Counts */}
                                <span style={{ color: '#999', fontSize: "0.85rem", fontWeight: '500', whiteSpace: 'pre-wrap', cursor: 'pointer' }}>Load More Responses</span>
                            </div>
                        </div>
                    </div>
                }

            </LoadingOverlay>

        </TransitionGroup >
    )
}

export default ScreenerAnalytics
