import React, { useState, useEffect } from 'react'
import { VerticalTimelineElement } from 'react-vertical-timeline-component'
import styles from '../../../../../assets/css/styling.module.css'
import { getActivityReplyImages } from '../../../../../actions/activities_reply_actions'
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player'
// ************* This component is used in ADMIN &RESEARCHER END to display survey Responses in Survey Activity & Survey Analytics Screen *****************

const SurveyResponsesView = (props) => {

    const { question } = props

    const [replyImage, setReplyImage] = useState({});
    const [replyImages, setReplyImages] = useState([]);

    const dispatch = useDispatch()
   
    const replyPath = question.responseFiles



    useEffect(() => {
        if (replyPath) {
            dispatch(getActivityReplyImages(replyPath))
                // .then((response) => {
                //  console.log('replyImage array ->', response.payload.content)
                //  let replyImagesArray = response.payload.content
                //  replyImagesArray.map(imagePath =>{
                //     console.log('individual img ->', imagePath.fileData)
                //     setReplyImage(imagePath.fileData)
                //  })
                .then((response) => {
                    
                    let replyImagesArray = response.payload.content;
                
                    // Set the state for all images
                    setReplyImages(replyImagesArray.map(imagePath => imagePath.fileData));
                  
                  

                    //setReplyImage(response.payload.content.fileData);
                })
                .catch(err => {
                    // Handle error if needed
                });
        }
    }, [dispatch, replyPath])

    return (
        <VerticalTimelineElement className="vertical-timeline-item">
            {/* <h4 className="timeline-title" style={{ fontWeight: '500' }}>{question.questionText}</h4> */}
{console.log('question ->', question)}
            <b><p dangerouslySetInnerHTML={{ __html: question.questionText }}></p></b>
            <ul className={`pl-3 ${styles.text_blue} ${styles.custom_list}`}>

                {
                    (question.questionType === 'spectrum' || question.questionType === 'radio' || question.questionType === 'radio script' || question.questionType === 'checkbox' || question.questionType === 'checkbox script' || question.questionType === 'slider') &&
                    question.selectedOptions && question.selectedOptions.length > 0 && question.selectedOptions.map(option => {
                        if (option !== null) {

                            return <li style={{ fontSize: '0.85rem' }}>{option}</li>
                        }
                    })
                }
                {
                    question.questionType === 'long text' &&
                    question.responseText && question.responseText !== null && <li style={{ fontSize: '0.85rem' }}>{question.responseText}</li>
                }

                {
                    question.questionType === 'image' && question.responseFiles.length > 0 && question.responseFiles !== undefined && (
                        <>
                         
                          {replyImages.length > 0 && question.responseFiles.map((file, index) => (
                            <div className="position-relative my-3" key={index}>
                              {replyImages[index] && (
                                <img
                                  src={`data:${replyImages[index].type};base64,${replyImages[index].data}`}
                                  className="img-fluid border"
                                  style={{ borderRadius: '10px', maxHeight: 400 }}
                                  alt={`survey-img-${index}`}
                                  width="200px"
                                />
                              )}
                            </div>
                          ))}
                        </>
                      )
                }
                {
                    question.questionType === 'video' && question.responseFiles.length > 0 && question.responseFiles !== undefined && (
                        <>
                         
                          {replyImages.length > 0 && question.responseFiles.map((file, index) => (
                           
                            <div className="position-relative my-3" key={index}>
                              
                                <ReactPlayer
                                url={`data:${replyImages[index].type};base64,${replyImages[index].data}`}
                                controls
                                width='200px'
                                style={{ borderRadius: '10px', maxHeight: 200, maxWidth:400 }}
                            />
                                
                              
                            </div>
                          ))}
                        </>
                      )
                }
                {
                    question.questionType === 'video text' && question.responseFiles.length > 0 && question.responseFiles !== undefined && (
                        <>
                          {question.responseText && <li>{question.responseText}</li>}
                          {replyImages.length > 0 && question.responseFiles.map((file, index) => (
                           
                            <div className="position-relative my-3" key={index}>
                              
                                <ReactPlayer
                                url={`data:${replyImages[index].type};base64,${replyImages[index].data}`}
                                controls
                                width='200px'
                                style={{ borderRadius: '10px', maxHeight: 200, maxWidth:400 }}
                            />
                                
                              
                            </div>
                          ))}
                        </>
                      )
                }
                
               
                {
                    question.questionType === 'image text' && question.responseFiles.length > 0 && question.responseFiles != undefined && (
                        <>
                            {question.responseText && <li>{question.responseText}</li>}
                            
                            {replyImages.length > 0 && question.responseFiles.map((file, index) => (
                            <div className="position-relative my-3" key={index}>
                              {replyImages[index] && (
                                <img
                                  src={`data:${replyImages[index].type};base64,${replyImages[index].data}`}
                                  className="img-fluid border"
                                  style={{ borderRadius: '10px', maxHeight: 400 }}
                                  alt={`survey-img-${index}`}
                                  width="200px"
                                />
                              )}
                            </div>
                          ))}
                        </>
                    )
                } 
              



                {
                    question.questionType === 'grid' &&
                    question.gridResponse &&
                    question.gridResponse.map((grid) => {
                        if (grid !== null && grid.row !== null && grid.column !== null) {
                            return (
                                <li key={grid.column}>
                                    <span style={{ fontWeight: 400 }}>{grid.row}</span> : {grid.column}
                                </li>
                            );
                        }
                        return null; // If either grid.row or grid.column is null, don't render
                    })
                }


            </ul>


        </VerticalTimelineElement>
    )
}

export default SurveyResponsesView