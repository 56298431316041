import React, { Fragment } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';

import ResizeDetector from "react-resize-detector";
import { TransitionGroup } from 'react-transition-group';
import Group_Forums from './Group_Forums/Group_Forums';
import Main from './Main/Main';
import Group_Forum_Details from './Group_Forums/Group_Forum_Details';
import Project_Detail_View from './Projects/Project_Detail_View';
import Survey from './Survey/Survey';
import EvaluationSurvey from './Survey/EvaluationSurvey';
import Profile from './Profile/Profile';
import Referral from './Referral/Referral';
import Add_Referral from './Referral/Add_Referral';
import Research_History from './Research_History/Research_History';
import InpersonsonInterview from './Projects/Activity/InpersonInterview/InpersonInterview';
import OnlineInterview from './Projects/Activity/OnlineInterview/OnlineInterview';
import Journal from './Projects/Activity/Journal/Journal';
import SurveyAnalysis from './Survey/SurverAnalysis';
import QuickSurvey2 from './QuickSurvey/QuickSurvey_2';
import QuickSurvey from './QuickSurvey/QuickSurvey';
import Forum from './Projects/Activity/forum/Forum';
import Chat from '../Admin/Chats/Chat';
import QuickSurveyListing from './QuickSurvey/QuickSurveyListing';
import Dashboard from './Dashboard/Dashboard';
import ProjectsList from './Projects/ProjectsList';
import NotificationsSection from './Notifications/NotificationsSection';
import FAQ from './FAQ/FAQ';
import LimitExceeded from '../SharableSurveys/LimitExceeded';
const Respondent = () => {
    const navigate = useNavigate()
    return (

        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <Fragment>
                    <AppHeader />

                    {/* Chat Icon */}
                    {/* <div style={{ position: 'fixed', zIndex: 12, bottom: '50px', left: '30px', filter: 'drop-shadow(5px 10px 4px #999)' }}>
                        <a href="/respondent/chat" title="Chat">
                            <img src={require('../../assets/images/messengerChat.png')} width='85' alt="" />
                        </a>
                    </div> */}

                    {width >= 1250 && <AppSidebar width={width} navigate={navigate} />}
                    <div className="app-main">
                        {width < 1250 && <AppSidebar width={width} navigate={navigate} />}
                        <div className="app-main__outer">
                            <div className="app-main__inner">

                                <div className="app-inner-layout">

                                    <TransitionGroup>

                                        {/* All Respondent Routes  */}

                                        <Routes>
                                            {/* main dashboard  */}
                                            <Route path="/" element={<Main />} />
                                            <Route path='/projects' element={<ProjectsList />} />
                                            <Route path="/group-forum-detail/:forumid" exact element={<Group_Forum_Details />} />
                                            <Route path="/project-detail-view/:projectid" exact element={<Project_Detail_View />} />
                                            <Route path='group-forums' exact element={<Group_Forums />} />
                                            <Route path='/group-forums/group-forum-detail/:forumid' exact element={<Group_Forum_Details />} />
                                            <Route path="/project/interview/:projectid/:interviewid" exact element={<InpersonsonInterview />} />
                                            <Route path="/project/onlineinterview/:projectid/:onlineinterviewid" exact element={<OnlineInterview />} />
                                            <Route path="/project/journal/:projectid/:journalid" exact element={<Journal />} />
                                            <Route path="/project/forum/:projectid/:forumid" exact element={<Forum />} />

                                            <Route path="/survey/:surveyid/:page" exact element={<Survey />} />
                                            <Route path="/survey/:surveyid" exact element={<Survey />} />
                                            <Route path="/evaluation/:surveyid" exact element={<EvaluationSurvey />} />
                                            <Route path="/survey-analysis/:surveyid/:responseid" exact element={<SurveyAnalysis />} />
                                            <Route path="/profile" exact element={<Profile />} />
                                            <Route path="/referral" exact element={<Referral />} />
                                            <Route path="/add-referral" exact element={<Add_Referral />} />
                                            <Route path="/research-history" exact element={<Research_History />} />
                                            <Route path="/limit-exceeded" element={<LimitExceeded/>} />
                                            {/* script enabled survey */}
                                            <Route path="/scripted-survey/:surveyid/:surveytype" exact element={<QuickSurvey2 />} />

                                            {/* simple survey */}
                                            <Route path="/quick-survey/:surveyid" exact element={<QuickSurvey />} />

                                            {/* <Route path="/sharable-quick-survey/:ActivityId"  component={QuickSurvey} />  */}
                                            <Route path="/quick-survey" exact element={<QuickSurveyListing />} />
                                            <Route path="/notifications" exact element={<NotificationsSection />} />
                                            <Route path="/faqs" exact element={<FAQ />} />

                                            <Route path="/chat" exact element={<Chat />} />

                                        </Routes>
                                    </TransitionGroup>
                                </div>

                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        />

    )

}

export default Respondent;