import React, { useEffect, useState } from 'react'
import DashboardIcon from '../../../assets/images/dashboard_header_icon.png'
import styles from '../../../assets/css/rstyling.module.css'
import { TransitionGroup } from 'react-transition-group';
import CountUp from "react-countup";
import { Card, CardBody } from "reactstrap";
import Slider from "react-slick";
import ResizeDetector from "react-resize-detector";
import Listing from "../../../assets/components/listing/Listing";
import Eyeicon from '../../../assets/images/eye_icon.png'
import Search from '../../../assets/images/search_icon.png'
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import Loader from "react-loaders";
import { adminDashboardDetails } from '../../../actions/dashboard_activities';
import { participantsProjects, ResearcherProjects } from '../../../actions/project_actions'

const Dashboard = () => {

    const projectState = useSelector(state => state.Project)
    const [isLoading, setLoading] = useState(true)
    const dispatch = useDispatch()

    const [projectColumn, setProjectColumn] = useState([
        { Name: 'Projects', Type: 'string', key: 'title' },
        { Name: "Start Date", Type: 'projectstartdate', key: 'startDate' },
        { Name: "End Date", Type: 'projectenddate', key: 'endDate' },
        { Name: "Project Type", Type: 'string', key: 'projectType' },
        // { Name: "Research Type", Type: 'string', key: 'researchType' },
        // { Name: "Priority", Type: 'string', key: 'priority' },
        // { Name: "Status", Type: 'string', key: 'status' },
        {
            Name: "Action", Type: 'action', options: [
                {
                    icon: Eyeicon,
                    // onClick: () => console.log("I'm clicked"),
                    type: 'dropdown',
                    droplist: [
                        { Name: 'Activities', url: "/researcher/project/activity" },
                        { Name: 'Respondents', url: "/researcher/project/participants" }
                    ]
                },
            ]
        }
    ]);

    const [activeProjects, setActiveProjects] = useState([])
    const [dashboardDetails, setDashboardDetails] = useState([])

    useEffect(() => {
        getDashboardDetails()
        getActiveProjectsListing()
    }, [])

    const getActiveProjectsListing = (currentPage = 1, dataLimit = 5) => {
        dispatch(ResearcherProjects(currentPage, dataLimit ))
            .then(response => {
                setActiveProjects(response.payload.content.projects)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)

            })
    }

    // Getting Dashboard stats
    const getDashboardDetails = () => {
        dispatch(adminDashboardDetails())
            .then(response => {
                setDashboardDetails(response.payload.content)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)

            })
    }

    // Searching
    const [searchString, setSearchString] = useState('')
    const [searchedProjectResult, setSearchedProjectResult] = useState([])

    useEffect(() => {
        var search = new RegExp(searchString, 'i');
        let filteredProject = activeProjects.filter(row => {
            return (
                search.test(row.title) ||
                search.test(row.type) ||
                search.test(row.company) ||
                search.test(row.status) ||
                search.test(row.code)
            );
        });
        setSearchedProjectResult(filteredProject);
    }, [searchString]);


    // Top cards settings and their breakpoints
    let settings = {
        centerMode: false,
        infinite: true,
        speed: 500,
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 4,
        swipeToSlide: true,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true,
                },
            },
        ],
    };

    return (
        <ResizeDetector
            handleWidth
            render={({ width }) => (
                <>

                    <div className="admin_header">
                        <div className="page_header">
                            <div className="app-page-title">
                                <div className="page-title-wrapper">
                                    <div className="page-title-heading">
                                        <div className="page-title-icon">
                                            <img src={DashboardIcon} alt="icon" />
                                        </div>
                                        <div>
                                            Dashboard
                                            <div className="page-title-subheading">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-title-actions">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">

                        <TransitionGroup component="div"transitionName="TabsAnimation" transitionAppear={true}
                            transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>


                            {/* Cards */}

                            <div className=" custom_slider">
                                <div className={`slick-slider-lg pb-3`} style={{ width: "100%" }}>
                                    <Slider {...settings}>
                                        <div >
                                            <div className={` ${styles.gradient_grbl} card  widget-content`} style={{ boxShadow: 'none', border: '0', marginRight: '10px', height: '90px' }}>
                                                <div className="widget-content-wrapper text-white">
                                                    <div className="widget-content-left">
                                                        <div className={`${styles.card_title} widget-heading font-size-lg`}>Projects</div>
                                                        <div className={`${styles.card_text} widget-subheading font-size-md`}>Total Projects</div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-white">
                                                            <CountUp start={0}
                                                                end={dashboardDetails.totalProjects ? dashboardDetails.totalProjects : 2} separator="" decimals={0} decimal="," prefix="" suffix="" duration="15" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div >
                                            <div className={` ${styles.gradient_grbl} card  widget-content`} style={{ boxShadow: 'none', border: '0', marginRight: '10px', height: '90px' }}>
                                                <div className="widget-content-wrapper text-white">
                                                    <div className="widget-content-left">
                                                        <div className={`${styles.card_title} widget-heading font-size-lg`}>Users</div>
                                                        <div className={`${styles.card_text} widget-subheading font-size-md`}>Total Users in System</div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-white">
                                                            <CountUp start={0} end={dashboardDetails.totalUsers ? dashboardDetails.totalUsers : 0} separator="" decimals={0} decimal="," prefix="" suffix="" duration="15" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div >
                                            <div className={` ${styles.gradient_grbl} card  widget-content`} style={{ boxShadow: 'none', border: '0', marginRight: '10px', height: '90px' }}>
                                                <div className="widget-content-wrapper text-white">
                                                    <div className="widget-content-left">
                                                        <div className={`${styles.card_title} widget-heading font-size-lg`}>New Users</div>
                                                        <div className={`${styles.card_text} widget-subheading font-size-md`}>New Users in System</div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-white">
                                                            <CountUp start={0} end={dashboardDetails.totalNewUsers ? dashboardDetails.totalNewUsers : 0} separator="" decimals={0} decimal="," prefix="" suffix="" duration="15" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div >
                                            <div className={` ${styles.gradient_grbl} card  widget-content`} style={{ boxShadow: 'none', border: '0', marginRight: '10px', height: '90px' }}>
                                                <div className="widget-content-wrapper text-white">
                                                    <div className="widget-content-left">
                                                        <div className={`${styles.card_title} widget-heading font-size-lg`}>Conversions</div>
                                                        <div className={`${styles.card_text} widget-subheading font-size-md`}>Conversions in System</div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="widget-numbers text-white">
                                                            <CountUp start={0} end={dashboardDetails.conversionsPercentage ? dashboardDetails.conversionsPercentage : 0} separator="" decimals={0.62} decimal="," prefix="" suffix="%" duration="15" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>

                            {/* Project Listing */}

                            <Card className="main-card mb-3">
                                <nav className="navbar navbar-expand-sm navbar-light">
                                    <div className="text-white menu-header-title text-capitalize text-muted ml-0" style={{ fontWeight: 500 }}>Projects</div>
                                    <div className={width <= 470 ? "" : "collapse navbar-collapse"}>
                                        <ul className="navbar-nav mr-auto">
                                        </ul>
                                        <form className="form-inline my-2 my-lg-0" style={{ marginRight: '-2rem' }}>
                                            <div className="input-group input-group-sm w-75">
                                                <div className="input-group-prepend border-0">
                                                    <span className={`bg-white my-sm-0 input-group-text form-control-sm ${styles.search_input}`}> <img src={Search} alt="search" /></span>
                                                </div>
                                                <input className={`form-control input-sm mr-sm-2 p-0 m-0 border-left-0 form-control-sm ${styles.search_input}`} type="search"
                                                    placeholder="Search" aria-label="Search" style={{ borderTopLeftRadius: '0' }} onChange={(e) => setSearchString(e.target.value)} />
                                            </div>
                                        </form>
                                    </div>
                                </nav>

                                <LoadingOverlay tag="div" active={isLoading}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: "#fff",
                                            opacity: 0.5,
                                        }),
                                    }}
                                    spinner={
                                        <Loader color="#6c757d" active type="ball-pulse" />
                                    }>
                                    <CardBody className="p-0">

                                        {/* Listing */}
                                        <div className="table_border">
                                            <Listing columnName={projectColumn}
                                                data={(searchString.length > 0 || searchedProjectResult.length > 0) ? searchedProjectResult : activeProjects}
                                                currentPage={projectState.currentPage ? projectState.currentPage : 1}
                                                totalPages={projectState.totalPages ? projectState.totalPages : 1}
                                                getListingData={(currentPage, dataLimit) => getActiveProjectsListing(currentPage, dataLimit)}
                                                startLoading={() => setLoading(true)}
                                            />
                                        </div>

                                    </CardBody>
                                </LoadingOverlay>
                            </Card>


                        </TransitionGroup>

                    </div>

                </>
            )}
        />
    )
}

export default Dashboard