import React, { Fragment, useEffect, useState, useContext } from "react";
import { BrowserRouter, Route, Routes, Redirect, useNavigate, useLocation } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import { ToastContainer, toast } from "react-toastify";
import Respondent from './pages/Respondent/Respondent';
import Admin from "./pages/Admin/Admin";
import AccountVerification from "./pages/AccountVerification/AccountVerification";
import ForgetPassword2 from "./pages/ForgetPassword/ForgetPassword2";
import Signin2 from "./pages/Signin/Signin2";
import ResearcherInvite from "./pages/ResearcherInvite/ResearcherInvite";
import Create_profile from "./pages/Create Profile/Create_Profile";
import Signup from "./pages/Signup/Signup";
import AccountRecovery2 from "./pages/AccountRecovery/AccountRecovery2";
import Researcher from "./pages/Researcher/Researcher";
import { requestFirebaseNotificationPermission, messaging } from './config/Firebase'
import { onMessage } from "firebase/messaging";
import SecureLS from 'secure-ls';
import axios from 'axios'
import ProjectInvitation from "./pages/Admin/Project/Participants/ProjectInvitation";
import MyProfile from "./pages/Researcher/Profile/MyProfile";
import { editUserDetails } from "./actions/user_action";
import { useDispatch } from 'react-redux'
import { vapidKey } from "./config/Firebase";
import { getToken } from "firebase/messaging";
import { refreshToken } from "./actions/auth_actions";
import Swal from 'sweetalert'
import { updateLogs } from "./actions/logs_action";
import SetProfileOption from "./pages/Create Profile/SetProfileOption";
import GuestProfile from "./pages/Create Profile/GuestProfile";
import Choice from "./pages/Signup/Choice";
import Choice2 from "./pages/Signup/Choice2";
import OtpForm2 from "./pages/Signup/OtpForm2";
import TryAgain from "./pages/Signup/TryAgain";
import PhoneForm2 from "./pages/Signup/PhoneForm2";
import EmailForm from "./pages/Signup/EmailForm";
import UserDetailsProvider from './UserDetailsProvider';
import QuickSurvey2 from "./pages/SharableSurveys/QuickSurvey2"
import SignupRequirement from "./pages/SharableSurveys/SignupRequirement";
import ThanksYouMsg from "./pages/SharableSurveys/ThankYouMsg";
import ChoiceExternalSurveys from "./pages/SharableSurveys/ChoiceExternalSurveys";
import GuestProfile2 from "./pages/Create Profile/GuestProfile2";
import LoginExternalSurveys2 from "./pages/SharableSurveys/LoginExternalSurveys2";
import CreateProfile2 from "./pages/SharableSurveys/CreateProfile2";
import PhoneExternalSurveys2 from "./pages/SharableSurveys/PhoneExternalSurveys2";
import EmailExternalSurveys from "./pages/SharableSurveys/EmailExternalSurveys";
import OtpExternalSurveys2 from "./pages/SharableSurveys/OtpExternalSurveys2";
import OtpAgainExSurveys2 from "./pages/SharableSurveys/OtpAgainExSurveys2";
import SignupExternalSurveys from "./pages/SharableSurveys/SignupExternalSurveys";
import SignupToken from "./pages/Signup/SignupToken";
import ScriptedSharableSurveys from "./pages/SharableSurveys/ScriptedSharableSurveys";
import RouteChangeTracker from './RouteChangeTracker' ;
import FirstPage from "./pages/Signin/LandingPage";
import LandingPage from "./pages/Signin/LandingPage";
import LandingPageExternalSurveys from "./pages/SharableSurveys/LandingPageExternalSurveys";
import ChoiceExternalSurveys2 from "./pages/SharableSurveys/ChoiceExternalSurveys2";
import AccntRecoverExtSurveys from "./pages/SharableSurveys/AccntRecoverExtSurveys";
import ForgetPassExtSurveys from "./pages/SharableSurveys/ForgetPassExtSurveys";
import TryAgain2 from "./pages/Signup/TryAgain2";
import LimitExceeded from "./pages/SharableSurveys/LimitExceeded";
import TikTokCallback from "./pages/Signup/TiktokCallback";
import OulaLandingPage from "./pages/SharableSurveys/OulaLandingPage";


const App = (props) => {

	let {
		colorScheme,
		enableFixedHeader,
		enableFixedSidebar,
		enableFixedFooter,
		enableClosedSidebar,
		closedSmallerSidebar,
		enableMobileMenu,
		enablePageTabsAlt,
	} = props;

	const authState = useSelector(state => state.Auth)
	const dispatch = useDispatch()
	//const history = useNavigate()
	

	
	

	
	
	const reqNotification = () => {
		try {
			Notification.requestPermission().then(function(permission)  {
				
				if (permission == "granted") {
					// save Token
					// getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
                 
					// 	if (Object.keys(authState.logged_in_user_data)) {
					// 		// const userId = authState.logged_in_user_data._id
					// 		// console.log('user id in app.js->', userId)
					// 		// console.log('user id in app.js->', currentToken)
					// 		// dispatch(editUserDetails(userId, { fcmToken: currentToken }))
					// 		// 	.then(res => {
					// 		// 		console.log(res)
					// 		// 	})
					// 		// 	.catch(err => console.log(err))
					// 	} else {
					// 		console.log('No loggedIn details');
					// 	}
					// }).catch((err) => {
					// 	console.log('An error occurred while retrieving token. ', err);
					// });
				} 
				
				else {
					console.log("No registration token")
				}

			})
		}
		
		catch (error) {
			console.error("Notification Error:",error);

		}
	}
	// Ask permissions for notifications
	useEffect(() => {
		reqNotification()
	}, [authState.logged_in_user_data])

	const Msg = ({ title, body }) => (
		<div>
			<p style={{ fontWeight: '600' }} className="m-0">{title}</p>
			<p className="mb-2">{body}</p>
		</div>
	)

	// Show notifications when the tab is active
	onMessage(messaging, (payload) => {
		const message = ''
		toast.info(<Msg title={payload.notification.title} body={payload.notification.body} />, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
		});
	});
	const ls = new SecureLS();

	const clearCookies = () => {
        if (document.cookie) {
          document.cookie.split(";").forEach((c) => {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
          });
        }
      };
    
      const clearCaches = async () => {
        // Log caches before clearing
        if ('caches' in window) {
			
          const cacheNamesBefore = await caches.keys();
          console.log('Caches before clearing:', cacheNamesBefore);
        }
    
        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();
        clearCookies();
    
        // Clear service worker caches
        if ('caches' in window) {
          const cacheNames = await caches.keys();
          await Promise.all(cacheNames.map((name) => caches.delete(name)));
    
          // Log caches after clearing
          const cacheNamesAfter = await caches.keys();
          console.log('Caches after clearing:', cacheNamesAfter);
        }
      };
	const checkUserToken = () => {
		//clearCaches()

		let token = ls.get('accessToken').accessToken;
		
		// for saving users token in request headers
		axios.interceptors.request.use(
			(config) => {
				const token = ls.get('accessToken').accessToken;
				if (token) {
					config.headers["x-access-token"] = token; // for Node.js Express back-end
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		// for refreshing token when getting 401 in response
		axios.interceptors.response.use(
			(res) => {
				return res;
			},
			async (err) => {
				const originalConfig = err.config;
				if ((window.location.pathname !== `/signup` || window.location.pathname !== `/login`) && err.response) {
					// Access Token was expired
					if (err.response.status === 401 && !originalConfig._retry) {
						originalConfig._retry = true;
						try {
							// asking for token refresh
							if (ls.get('refreshToken')) {
								dispatch(refreshToken({ refreshToken: ls.get('refreshToken') }))
									.then((response) => {
										ls.set('accessToken', { accessToken: response.payload.content.accessToken, role: response.payload.content.role })
									})
									.catch((err) => {
										window.localStorage.clear();
										Swal({
											title: 'Session Expired',
											text: 'Please login again',
											icon: 'error'
										})
										window.location.href = '/login'

									})
							}

						} catch (_error) {
							console.log("ERR", _error)
							return Promise.reject(_error);
						}
					}
				}
				return Promise.reject(err);
			}
		);

		if (token)
			axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

		else
			window.localStorage.clear();

			
		 //console.log(currentPath)
		// access quick surveys pages as guest user 
	// 	if (ls.get('accessToken').role === 3 && ls.get('userStatus') === 'partially_complete'
	// 		&& currentPath.split('/')[1] !== 'create_profile'
	// 		&& currentPath.split('/')[1] !== 'guest_profile'
	// 		&& currentPath.split('/')[2] !== 'quick-survey'
	// 		&& currentPath.split('/')[1] !== 'login'

	// 		) {

	// 		ls.get('userType') === 'guest' ?
	// 			history('/respondent/quick-survey') :
	// 			history('/set_profile')
	// 	}

	 }
	// changing users active status to false before closing the tab
	window.addEventListener("beforeunload", (ev) => {
		//ev.preventDefault();
		if (ls.get('accessToken').accessToken) {
			dispatch(updateLogs({ isActive: false }))
				.then(response => {
					console.log(response)
				})
				.catch(err => {
					console.log(err)
				})
		}
	});
	
	// changing users active status based on their online status
	useEffect(() => {
		if (ls.get('accessToken').accessToken) {
			dispatch(updateLogs({ isActive: window.navigator.onLine }))
				.then(response => {
					console.log(response)
				})
				.catch(err => {
					console.log(err)
				})
		}
	}, [window.navigator.onLine])

	



	return (

		<ResizeDetector
			handleWidth
			render={({ width }) => (
				<Fragment>
					<div 
						className={cx(
							"app-container app-theme-" + colorScheme,
							{ "fixed-header": enableFixedHeader },
							{ "fixed-sidebar": enableFixedSidebar || width < 1250 },
							{ "fixed-footer": enableFixedFooter },
							{ "closed-sidebar": enableClosedSidebar || width < 1250 },
							{
								"closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
							},
							{ "sidebar-mobile-open": enableMobileMenu },
							{ "body-tabs-shadow-btn": enablePageTabsAlt }
						)}>

						{/* App Main Routes */}
						 <BrowserRouter> 
						 <RouteChangeTracker />
							<Routes>
							{/* {clearCaches()} */}
								{checkUserToken()}
								
								{/* Respondent Pages */}
								
								{
									
									 ls.get('accessToken').role === 3 &&
									<Route path="/respondent/*" element={<Respondent/>} />
									
									
								}

								{/* Admin Pages */}
								{
									ls.get('accessToken').role === 1 &&
									
									<Route path="/admin/*" element={<Admin/>} />
									
									
								}

								{/* Researcher Pages */}
								{
									ls.get('accessToken').role === 2 &&
									<Route path="/researcher/*" element={<Researcher/>} />
								}

								
								<Route path="/verify_account" element={<AccountVerification/>} />
								<Route path="/reset_password" element={<ForgetPassword2/>} />

								{/* Researcher */}
								
								
								<Route path="/project_invite" exact element={<ProjectInvitation/>} />
								
								<Route path="/invite_researcher/:token" element={<ResearcherInvite/>} />
                                
                                
								
								{/* <Route path="/phone" element={<PhoneForm/>}/>
								 <Route path='/email' element={<EmailForm/>} /> */}


                                {/*Sharable Surveys*/}

								<Route path="/sharable-quick-survey/:ActivityId/:UserId" element={<QuickSurvey2/>} />
								<Route path="/sharable-quick-scripted-survey/:ActivityId/:UserId" element={<ScriptedSharableSurveys/>} />
                                <Route path='/sharable-survey/:ActivityId' element={<LandingPageExternalSurveys/>} />
								<Route path="/thank-you-message" element={<ThanksYouMsg/>}  />
								{/* <Route path='/choose/:ActivityId' element={<ChoiceExternalSurveys/>} /> */}
								<Route path='/login/:ActivityId' element={<LoginExternalSurveys2/>} />
								{/* <Route path='/landingPage/:ActivityId' element={<LandingPageExternalSurveys/>} /> */}
								<Route path='/signup/:ActivityId' exact element={<SignupExternalSurveys/>} /> 
								<Route path="/set_profile2/:ActivityId" element={<CreateProfile2/>} />
								<Route path="/setup_profile/:ActivityId" element={<GuestProfile2/>} />
								<Route path="/phone/:ActivityId/:UserId" element={<PhoneExternalSurveys2/>} />
								<Route path="/email/:ActivityId/:UserId" element={<EmailExternalSurveys/>} /> 
								<Route path="/otp/:ActivityId/:UserId" element={<OtpExternalSurveys2/>} />
								<Route path="/otp-again/:ActivityId/:UserId" element={<OtpAgainExSurveys2/>} />  
								<Route path="/choose/:ActivityId" element={<ChoiceExternalSurveys2/>} />
								<Route path="/recover/:ActivityId" element={<AccntRecoverExtSurveys/>} />
								<Route path="/reset_password/:ActivityId" element={<ForgetPassExtSurveys/>} />
								<Route path="/limit-exceeded" element={<LimitExceeded/>} />
								<Route path="/oula/:UserId" element={<OulaLandingPage/>} />


								{/* Signup Pages */}
								{/* <UserDetailsProvider> */}
								
								<Route path="/signup" element={<UserDetailsProvider><Signup/></UserDetailsProvider>} />
								<Route path="/invite_signup/:token" element={<UserDetailsProvider><SignupToken/></UserDetailsProvider>}/>
								<Route path="/login" exact element={<UserDetailsProvider><Signin2/></UserDetailsProvider>} /> 
								 
                                <Route path="/choose" element={<UserDetailsProvider><Choice2/></UserDetailsProvider>} />
								<Route path="/callback" element={<TikTokCallback/>} />
								<Route path="/phone" element={<UserDetailsProvider><PhoneForm2/></UserDetailsProvider>} />
								<Route path="/email" element={<UserDetailsProvider><EmailForm/></UserDetailsProvider>} />
								<Route path="/otp-again" element={<UserDetailsProvider><TryAgain2/></UserDetailsProvider>}/>
								<Route path="/otp" element={<UserDetailsProvider><OtpForm2/></UserDetailsProvider>}/>
								<Route path="/create_profile" element={<UserDetailsProvider><SetProfileOption/></UserDetailsProvider>} />
								<Route path="/set_profile" element={<UserDetailsProvider><Create_profile/></UserDetailsProvider>} />
								<Route path="/set_up_profile" element={<UserDetailsProvider><GuestProfile/></UserDetailsProvider>} /> 
								<Route path="/recover" element={<UserDetailsProvider><AccountRecovery2/></UserDetailsProvider>} />
								<Route path="/" exact element={<UserDetailsProvider><LandingPage/></UserDetailsProvider>} />
									
								
								{/* <Route path="/guest_profile2" element={GuestProfile2} />   */}
								 {/* <Route path="/join-us" element={SignupRequirement}/>  */}
								 
								{/* </UserDetailsProvider> */}

								{/* <Route path='choose/:ActivityId' element={ChoiceExternalSurveys}/> */}
								{/* <Route path="/invite_respondent" element={Signup} /> */}
								
								

								{/* Create Profile */}
								{
									ls.get('accessToken').role === 3 &&
									<>
										{/* <Route path="/create_profile" element={SetProfileOption} /> */}
										{/* <Route path="/set_profile" element={Create_profile} />*/}
										{/* <Route path="/guest_profile" element={GuestProfile} />  */}
									</>
								}


								{/* Recover Account Pages */}
								{/* <Route path="/recover" element={AccountRecovery} />
								<Route path="/" element={Signin} /> */}


								{/* 404 Page */}
								{/* <Route path="/404" element={page404} />
								<Redirect to="/404" /> */}

							</Routes>
						 </BrowserRouter> 

						{/* React Toastify */}
						<ToastContainer
							position="top-right"
							autoClose={5000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable={false}
							pauseOnHover
						/>
					</div>
				</Fragment>
			)}
		/>


	);
}

const mapStateToProp = (state) => ({
	colorScheme: state.ThemeOptions.colorScheme,
	enableFixedHeader: state.ThemeOptions.enableFixedHeader,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableFixedFooter: state.ThemeOptions.enableFixedFooter,
	enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});


export default connect(mapStateToProp)(App);