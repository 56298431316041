import React, { useState } from 'react'
//import ReactTable from "react-table";
import ResizeDetector from 'react-resize-detector';
import Actions from './Actions'
import CrossIcon from '../../images/cross_icon.png'
import TickIcon from '../../images/tick_icon.png'
import classNames from 'classnames';
import Pagination from './Pagination';
import avatar from '../../images/profile_placeholder.png'
import DataTable from 'react-data-table-component';


const Listing = (props) => {
console.log('props in listing ->', props)
 
    const [listingDataLimit, setListingDataLimit] = useState(5)

    // For setting conditional styling on rows 
    // const getTrProps = (state, rowInfo, instance) => {
    //     if (rowInfo) {
    //         return {
    //             style: {
    //                 backgroundColor: rowInfo.original.isArchived ? '#eee' : 'white',
    //             }
    //         }
    //     }
    //     return {};
    // }

    const customRowStyles = (row) => ({
        backgroundColor: row.isArchived ? '#eee' : 'white',
      });

    // Setting dynamic column width based on ext length 
    // Can also hardcode the column width by sending it in props
    const getColumnWidth = (rows, accessor, headerText, type) => {

        const maxWidth = 500
        const magicSpacing = 10
        const cellLength = Math.max(
            ...rows.map(row => (type === 'date' || type === 'time' || type === 'userRole' ? '-- -- -- --' : `${row[accessor]}`).length),
            headerText.length,
            8
        )
       return Math.min(maxWidth, (cellLength + 1) * magicSpacing)
    }
    
    













const paginationOptions = {
    pagination: true,
    paginationComponent: Pagination,
    paginationPerPage: props.dataLimit ? props.dataLimit : listingDataLimit,
    paginationRowsPerPageOptions: [10, 20, 50],
    customStyles: {
      rows: {
        style: {
          minHeight: '50px', // Adjust the row height as needed
        },
      },
    },
  };


  const adjustedData = props.data.length < 5 ? [...props.data, ...Array(5 - props.data.length).fill({})] : props.data;

    return (
        <>
            <ResizeDetector
                handleWidth
                render={({width }) => (
                    <>
                         <div className="table_border" > 
                            

                       




  <DataTable
  data={props.data.length < 5 ? [...props.data, ...Array(5 - props.data.length).fill({})] : props.data}
 
 
  columns={props.columnName.map(col => {
    return {
      name: col.Name,
      selector: col.key,
      sortable: true,
      width: '200px',
      
      //width: col.width ? col.width : getColumnWidth(props.data, col.key, col.Name, col.Type),
      minWidth: props.columnName.length < 4 ? '' : 180,
     maxWidth: 'auto',
      cell: (row) => {
        
      //  console.log('col.key->', col.key)
      //  console.log('col.Name->', col.Name)
      //  console.log('col.Type->', col.Type)
        // Progress bar
        if (col.Type === 'status') {
          return (
            <div className="progress-bar-sm progress" style={{ width: "100%", backgroundColor: "#dadada" }}>
              <div className="progress-bar pl-5" style={{
                width: `${row.value}%`,
                backgroundColor: row.value > 66
                  ? "#3ac47d"
                  : row.value > 33
                    ? "#fd7e14"
                    : "#d92550",
                borderRadius: "2px",
                transition: "all .2s ease-out",
              }} />
            </div>
          );
        }
        // Image with Text
        else if (col.Type === "image text") {
          return (
            <div className="widget-content p-0">
              <div className="widget-content-wrapper">
                <div className="widget-content-left mr-3">
                  <div className="widget-content-left">
                    <img width={35} height={35} className="rounded-circle" src={avatar} alt="" />
                  </div>
                </div>
                <div className="widget-content-left flex2">
                  <div className="d-block w-100">
                    {/* {row.value.[col.value]} */}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        // Badge
        else if (col.Type === "badge") {
          return (
            <span className={classNames({
              'badge_success': row.value === 'Attended',
              'badge_primary': row.value === 'Sheduled',
              'badge_danger': row.value === 'Not Attended',
            }, 'mb-2 mr-2')}>
              <span className="rbt-token">
                {row.value}
              </span>
            </span>
          );
        }
        // Icons
        else if (col.Type === "icon") {
          return (
            <div>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left mr-3">
                    <div className="widget-content-left text-center">
                      <img width={20} height={20} src={row.value === '100%' ? TickIcon : CrossIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
        // Actions
        else if (col.Type === "action") {

          //return <>{row.title && <Actions options={col.options} row={row} />} </>
         
          return <Actions options={col.options} row={row} />;
        }
        // Date
        else if (col.Type === "date") {
        
          const createdDate = new Date(row.createdAt);
          return <div className="pl-2 ">{row.createdAt && createdDate.toLocaleDateString()}</div>;
        }
        else if (col.key === "status") {
         
          return <div className="pl-2 ">{row.status}</div>;
        }
        
        
        // Time
        else if (col.Type === "time") {
          const createdDate = new Date(row.createdAt);
          return <div className="pl-2 ">{row.createdAt && createdDate.toLocaleTimeString()}</div>;
        }
        // Serial No
        else if (col.Type === "serialNo") {
        
          return <div className="pl-2 ">
            
            </div>;
        }
        // User Role
        else if (col.Type === "userRole") {
          return (
            <div className="pl-2 ">
              {row.role === 1 ? 'Admin' : row.role === 3 ? 'Respondent' : 'Researcher'}
            </div>
          );
        }
        else if (col.key === "firstName") {
            return (
              <div className="pl-2 " >
                {row.firstName}
              </div>
            );
          }
          else if (col.key === "lastName") {
            return (
              <div className="pl-2 ">
                {row.lastName}
              </div>
            );
          }
          else if (col.key === "email") {
            return (
              <div className='py-4'>
                {row.email}
              </div>
            );
          }
          else if (col.key === "userName") {
            return (
              <div className="pl-2 ">
                {row.userName}
              </div>
            );
          }
          else if (col.key === "age") {
            return (
              <div className="pl-2 ">
                {row.age}
              </div>
            );
          }
          else if (col.key === "gender") {
            return (
              <div className="pl-2 ">
                {row.gender}
              </div>
            );
          }
          else if (col.key === "income") {
            return (
              <div className="pl-2 ">
                {row.income}
              </div>
            );
          }
          else if (col.key === "maritalStatus") {
            return (
              <div className="pl-2 ">
                {row.maritalStatus}
              </div>
            );
          }
          else if (col.key === "education") {
            return (
              <div className="pl-2 ">
                {row.education}
              </div>
            );
          }
          else if (col.key === "city") {
            return (
              <div className="pl-2 ">
                {row.city}
              </div>
            );
          }
          else if (col.key === "logs.pointsEarned") {
            return (
              <div className="pl-2 ">
                {/* {row.logs.pointsEarned} */}
              </div>
            );
          }
        // Object
        // else if (col.Type === "object") {
        //   return <div className="pl-2 ">
        //     {/* {row.value[col.value]} */}
        //     </div>;
        // }
        // Progress in %
        else if (col.Type === "progress") {
        
          return <div className="pl-2 ">
             {row.progress} 
             %</div>;
        }
        // Activity Status
        else if (col.Type === "activity status") {
          return <div className="pl-2 ">{row.status === 'scheduled' ? 'Publish' : row.status}</div>;
        }
        // File Type
        else if (col.Type === "file") {
          return <div className="pl-2 ">
            {/* {row.value.split('.').pop()} */}
            </div>;
        }
        // Attachments
        else if (col.Type === "attachment") {
          return (
            <></>
            // <a className="pl-2 text-muted" 
            // href={row.value}>
            //   {typeof row.value === 'string' ? row.value.split('/').pop() : row.value[0].split('/').pop()}
            // </a>
          );
        }
        else if (col.Name === "Project Name") {
          return <div className="pl-2 ">{row.title} </div>;
        }
        else if (col.Name === "Project Code") {
          return <div className="pl-2 ">{row.code} </div>;
        }
        else if (col.Type == "projectstartdate") {
           const stDate = new Date(row.startDate);
          // console.log('start date->', row.startDate)
          return <div className="pl-2 ">{row.startDate && stDate.toLocaleDateString()}</div>;
        }
        else if (col.Type == "projectenddate") {
          const edDate = new Date(row.endDate);
         
          return <div className="pl-2 ">{row.endDate && edDate.toLocaleDateString()}</div>;
        }
        else if (col.key === "projectType") {
          
          return <div className="pl-2 ">{row.projectType}</div>;
        }
        else if (col.key === "company") {
          
          return <div className="pl-2 ">{row.brand && row.brand.name}</div>;
        }
        //Quick Surveys
        else if(col.Name === 'Title'){
          return <div className='pl-2'>{row.name}</div>
        }
        else if(col.Name === 'Description'){
          return <div className='pl-2'>{row.description}</div>
        }
        else if (col.Type == "qsstartdate") {
          const stDate = new Date(row.startDate);
         // console.log('start date->', row.startDate)
         return <div className="pl-2 ">{row.startDate && stDate.toLocaleDateString()}</div>;
       }
       else if (col.Type == "qsenddate") {
         const edDate = new Date(row.endDate);
        
         return <div className="pl-2 ">{row.endDate && edDate.toLocaleDateString()}</div>;
       }
        //General forums
        else if(col.Name === 'Activity Name'){
          return <div className='pl-2'>{row.name}</div>
        }
        else if(col.Name == 'Activity Type'){
          return <div className='pl-2'>{row.activityType}</div>
        }
        else if (col.Type == "gfstartdate") {
          const stDate = new Date(row.startDate);
         // console.log('start date->', row.startDate)
         return <div className="pl-2 ">{row.startDate && stDate.toLocaleDateString()}</div>;
       }
       else if (col.Type == "gfenddate") {
         const edDate = new Date(row.endDate);
        
         return <div className="pl-2 ">{row.endDate && edDate.toLocaleDateString()}</div>;
       }

        // broadcast message
        else if(col.Name == 'Receivers'){
          return <div className='pl-2'>{row.receiver}</div>
        }
        else if(col.Name == 'Subject'){
          return <div className='pl-2'>{row.subject}</div>
        }
        //campaigns
        else if(col.Name == 'Name of Campaigns'){
          return <div className='pl-2'>{row.title}</div>
        }
        //emails
        else if(col.Name== 'Title Name'){
          return <div className='pl-2'>{row.title}</div>
        }
        //dashboard projects
        else if(col.Name=='Projects'){
          return <div className='px-2'>{row.title}</div>
        }
        else if(col.Name=='Priority'){
          return <div className='px-2'>{row.priority}</div>
        }
        else if(col.Name=='Research Type'){
          return <div className='px-2'>{row.researchType}</div>
        }
        //group discussions
        else if(col.Name == 'Topic'){
          return <div className='px-2'>{row.topic}</div>
        }
        else if(col.Name == 'Duration'){
          return <div className='px-2'>{row.duration}</div>
        }
        //particiapnts activity table list
        else if(col.Name == 'Respondent Name'){
          if (!row || !row.author || !row.author.userName) {
            // Handle the case where row, row.author, or row.author.email is undefined or null
            return null; // or display an error message, return a loading state, etc.
          }
          return <div className='px-2'>
            {row.author.userName}
            </div>
        }
        else if(col.Name == 'Serial No'){
          
          if (!row || !row.serialNo) {
            // Handle the case where row, row.author, or row.author.email is undefined or null
            return null; // or display an error message, return a loading state, etc.
          }
          return <div className='px-2'>
            {row.serialNo}
            </div>
        }
        else if(col.Name == 'Email'){
          if (!row || !row.author || !row.author.email) {
            // Handle the case where row, row.author, or row.author.email is undefined or null
            return null; // or display an error message, return a loading state, etc.
          }
          return <div className='px-2'>
            {row.author.email}
            </div>
        }
        else if(col.Name == 'Phone No'){
          if (!row || !row.author || !row.author.phone) {
            // Handle the case where row, row.author, or row.author.email is undefined or null
            return null; // or display an error message, return a loading state, etc.
          }
          return <div className='px-2'>
            {row.author.phone}
            </div>
        }
        else if(col.key == 'author.logs.latestVisit'){
          if (!row || !row.author || !row.author.logs || !row.author.logs.latestVisit) {
            // Handle the case where row, row.author, or row.author.email is undefined or null
            return null; // or display an error message, return a loading state, etc.
          }
          const lV = new Date(row.author.logs.latestVisit);
          return <div className="pl-2 ">{row.author.logs.latestVisit && lV.toLocaleDateString()}</div>;
            
        }
        // else if(col.Name == 'Date'){
        //   if (!row || !row.startDate) {
        //     // Handle the case where row, row.author, or row.author.email is undefined or null
        //     return null; // or display an error message, return a loading state, etc.
        //   }
        //   console.log('start date 2->', row.startDate)
        //   const createdDate = new Date(row.startDate);
        //   return <div className="pl-2 ">{row.startDate && createdDate.toLocaleDateString()}</div>
        // }
        //wordcloud
        else if(col.Name== 'Word'){
          if(!row || !row.text){return null}
          return<div className='pl-2'>{row.text}</div>
        }
        else if(col.Name== 'Frequency'){
          if(!row || !row.text){return null}
          return<div className='pl-2'>{row.value}</div>
        }
        // screener list
        else if(col.Name == 'Form Name'){
          if(!row || !row.name){return null}
          return<div className='pl-2'>{row.name}</div>
        }
       
        else if(col.Type == 'screenerdate'){
          if(!row || !row.startDate){return null}
          let stDate = new Date(row.startDate)
          return<div className='pl-2'>{row.startDate && stDate.toLocaleDateString()}</div>
        }
        // activity start and end date
        else if(col.Type == 'activitystartdate'){
          if(!row || !row.startDate){return null}
          let stDate = new Date(row.startDate)
          return<div className='pl-2'>{row.startDate && stDate.toLocaleDateString()}</div>
        }
        else if(col.Type == 'activityenddate'){
          if(!row || !row.endDate){return null}
          let edDate = new Date(row.endDate)
          return<div className='pl-2'>{row.endDate && edDate.toLocaleDateString()}</div>
        }

        // Simple Text
        else {
           // console.log('row.value->', row[value])
          return <div></div>
       }
      },
      // Column Heading
     
    };
  })}
 

  paginationTotalRows={props.data.length}
  paginationPerPage={5}
 
  
  
/>


















                            {/* Pagination */}
                            {
                                props.getListingData &&
                                <Pagination currentPage={props.currentPage} totalPages={props.totalPages} getListingData={props.getListingData}
                                    listingDataLimit={listingDataLimit} setListingDataLimit={setListingDataLimit}
                                    startLoading={props.startLoading}

                                />
                            }

                        </div>
                    </>
                )}
               
                
            />

        </>
    )
}

export default Listing


