import React, { useEffect, useState } from 'react'
import styles3 from '../../assets/css/newStyling.module.css';
import styles from '../../assets/css/rstyling.module.css';
import landing_page_logo from '../../assets/images/landing_page_logo.png'
import login_bg_new from "../../assets/images/login_bg2.png";
import { FormGroup, Label, Input, Button } from 'reactstrap'
import Swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import { forgetPassword } from '../../actions/auth_actions';
import { IoIosEye } from 'react-icons/io'
import { IoIosEyeOff } from 'react-icons/io'
import axios from 'axios'
import { BACK_SERVER_URL } from '../../config/server';
import { useNavigate, useLocation, useParams } from 'react-router-dom';


export default function ForgetPassExtSurveys() {

    const { ActivityId } = useParams()

    let { search } = useLocation();
    const query = new URLSearchParams(window.decodeURIComponent(search));
    const history = useNavigate()
    const [password, setPassword] = useState('')
    const [showPassword, setPassVisibility] = useState(false)
    const [err, setError] = useState({
        email: '',
        password: ''
    })

    const removeErr = () => {
        setError({
            password: ''
        })
    }

    const checkValidation = () => {
        removeErr()
        if (password === '') {
            setError((prevState) => ({ ...prevState, password: 'Password is required.' }))
        }
        if (password.split('').length < 8) {
            setError((prevState) => ({ ...prevState, password: 'Password must contain 8 characters.' }))
        }
        else {
            return true
        }
    }
    const resetPassword = () => {
        if (checkValidation()) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${query.get("token")}`;
            axios.put(BACK_SERVER_URL + "api/users/resetPassword?token=" + query.get("token"), { password: password })
                .then(response => {
                    Swal({
                        title: response.message ? response.message : "Password Reset",
                        text: response.content ? response.content : "Your password has been changed.",
                        icon: 'success'
                    })
                    .then( () => history(`/login/${ActivityId}`))
                })
                .catch(err => {
                    Swal({
                        title: err.response ? err.response.data.name : "Error",
                        text: err.response ? err.response.data.content : "Please try again!",
                        icon: 'error'
                    });
                })
        }
    }



  return (
    <div className="container-fluid p-2 px-4">
                <div className='row justify-content-center'>
                    <div className={`row p-2 ${styles3.login_container}`}>

                        <div className={`col-sm-5 col-12 p-0 align-self-center`}>

                            <div className={`${styles3.login_img}`}
                                style={{
                                    backgroundImage: "url(" + login_bg_new + ")",
                                }} />


                        </div>

                        <div className="col-sm-7 col-12 bg-white align-self-center">
                            <div className='row justify-content-center'>
                                <div className={`${styles3.login_section}`}>
                                    {/* <ul className="nav justify-content-center pt-5">
        <li className="nav-item">
            <img src={landing_page_logo} alt="logo" width="250px" style={{ margin: '2rem 1rem 0 0' }} />
        </li >
       
    </ul> */}
                                    <div className=" container" >



                                        <div className="container text-center">
                                            <img className={`${window.innerWidth < 768 ? ' d-none': styles3.login_logo}`} src={landing_page_logo} alt="logo" width="200px" />
                                            <p className ={`${window.innerWidth < 768 ? ' mt-4': ''}`} style={{ color: '#000000', fontWeight: '600', fontSize: '1rem', paddingTop:'50px' }}>Reset Password.</p>
                                            <p style={{color: 'rgb(168, 168, 168)', fontSize: '12px', fontWeight: 'normal'}}>Please enter a new password to reset your account.</p>

                                            <div>
                                            <div>
                                            <FormGroup className="mt-5">
                                            <Label className={styles.password}>New Password
                                                <Input type={showPassword ? "text" : "password"} onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Enter your new password" className={err.password ? styles.is_invalid + " mt-2" : 'valid mt-2'} style={{paddingTop:'28px', paddingBottom:'28px'}}/>
                                                <span className={styles.password_ico_position} onClick={() => setPassVisibility(!showPassword)}>{showPassword ? <IoIosEye /> : <IoIosEyeOff />}</span>
                                            </Label>
                                            <small className="text-danger">
                                                {err.password}
                                            </small>

                                        </FormGroup>
                                    </div>

                                               


                                               
                                                <button className={`btn-hover-shine btn text-light btn-block py-3 mt-4`} style={{ backgroundColor: '#006FA7', fontSize: '1rem' }} onClick={() => resetPassword()}>
                                                    Reset Password
                                                </button>
                                               
                                            </div>
                                           












                                        </div>
                                    </div>
                                </div>
                            </div>










                        </div>
                    </div>
                </div>
            </div>
  )
}